import React from 'react';

export const TargetIcon = () => (
  <div className="mr-1.5 h-3 w-3 shrink-0 text-gray-400">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 503.607 503.607"
      // style="enable-background:new 0 0 503.607 503.607;"
      xmlSpace="preserve"
    >
      <g transform="translate(1 1)">
        <g>
          <g>
            <path
              d="M250.803-1C112.311-1-1,111.472-1,250.803s113.311,251.803,251.803,251.803s251.803-113.311,251.803-251.803
              S389.295-1,250.803-1z M250.803,485.82c-129.259,0-235.016-105.757-235.016-235.016S121.544,15.787,250.803,15.787
              S485.82,121.544,485.82,250.803S380.062,485.82,250.803,485.82z"
            />
            <path
              d="M250.803,32.574c-120.026,0-218.229,98.203-218.229,218.229c0,120.866,98.203,218.23,218.229,218.23
              s218.23-97.364,218.23-218.23C469.033,130.777,370.829,32.574,250.803,32.574z M259.197,452.057V317.951h16.787
              c5.036,0,8.393-3.357,8.393-8.393s-3.357-8.393-8.393-8.393h-50.361c-5.036,0-8.393,3.357-8.393,8.393s3.357,8.393,8.393,8.393
              h16.787v134.106c-104.155-4.325-188.534-88.705-192.86-192.86h134.106v16.787c0,5.036,3.357,8.393,8.393,8.393
              s8.393-3.357,8.393-8.393v-50.361c0-5.036-3.357-8.393-8.393-8.393s-8.393,3.357-8.393,8.393v16.787H49.55
              c4.325-104.155,88.705-188.534,192.86-192.86v134.106h-16.787c-5.036,0-8.393,3.357-8.393,8.393s3.357,8.393,8.393,8.393h50.361
              c5.036,0,8.393-3.357,8.393-8.393s-3.357-8.393-8.393-8.393h-16.787V49.55c104.155,4.325,188.534,88.705,192.86,192.86H317.951
              v-16.787c0-5.036-3.357-8.393-8.393-8.393s-8.393,3.357-8.393,8.393v50.361c0,5.036,3.357,8.393,8.393,8.393
              s8.393-3.357,8.393-8.393v-16.787h134.106C447.731,363.351,363.351,447.731,259.197,452.057z"
            />
            <path
              d="M250.803,234.016c-9.233,0-16.787,7.554-16.787,16.787c0,9.233,7.554,16.787,16.787,16.787
              c9.233,0,16.787-7.554,16.787-16.787C267.59,241.57,260.036,234.016,250.803,234.016z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
);
