import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'context/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
import { Loading } from 'components/Loading';
import { QUERY_KEYS } from '../../constants';

export const RequireAuth = () => {
  const [cookies] = useCookies(['loggedIn']);
  const location = useLocation();
  const axios = useApi();

  const { state, dispatch } = useAuth();

  const query = useQuery(
    [QUERY_KEYS.USER],
    async () => {
      const response = await axios.get('/auth/me');
      return response.data;
    },
    {
      retry: 1,
      enabled: !!cookies.loggedIn,
      onSuccess: (data) => {
        console.log('SUCCESS_DATA', data);
        dispatch({ type: 'SET_USER', payload: data });
      },
    }
  );

  if (query.isLoading && cookies.loggedIn && !state.accessToken) {
    return <Loading />;
  }

  return state.accessToken && cookies.loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
