import React from 'react';
import Map, { Marker, GeolocateControl, useMap } from 'react-map-gl';
import { useEffect, useState } from 'react';
import { Loading } from '../../Loading';
import { PropertyFeatureModel } from '../../../pages/AddFeature/feature.models';

const TOKEN = import.meta.env.VITE_MAPBOX_KEY; // Set your mapbox token here

type LatLng = {
  lat: number;
  lng: number;
};

interface CustomMapProps {
  features: PropertyFeatureModel[];
  initialLocation: LatLng;
  mapId: string;
  customStyles?: React.CSSProperties;
}

export const FeatureMap = ({
  customStyles = {},
  features,
  initialLocation,
  mapId,
}: CustomMapProps) => {
  const { customMap } = useMap();
  const [center, setCenter] = useState<LatLng | null>(null);

  useEffect(() => {
    setCenter(initialLocation);
  }, [initialLocation]);

  useEffect(() => {
    if (!customMap || !center) {
      return;
    }

    customMap.flyTo({
      center: [center.lng, center.lat],
      essential: true,
      curve: 0.5,
    });
  });

  if (!center) {
    return <Loading />;
  }

  return (
    <div style={{ height: '800px', ...customStyles }}>
      <Map
        id={mapId}
        initialViewState={{
          longitude: initialLocation.lng,
          latitude: initialLocation.lat,
          zoom: 17,
        }}
        cursor="crosshair"
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken={TOKEN}
        onClick={(data) => {
          console.log('MapClicked', data);
        }}
      >
        <GeolocateControl position="top-left" />
        {features.length > 0
          ? features.map((item) => {
              console.log('FEATRE', item);
              return (
                <Marker
                  anchor="bottom"
                  key={item.id}
                  latitude={item.lat}
                  longitude={item.lng}
                  onClick={(data) => {
                    console.log('MARKER', data);
                    data.originalEvent.stopPropagation();
                  }}
                >
                  <img
                    style={{ cursor: 'pointer' }}
                    src="https://img.icons8.com/ios-filled/24/F7F808/visit.png"
                  />
                </Marker>
              );
            })
          : null}
      </Map>
    </div>
  );
};
