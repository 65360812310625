import React, { ReactNode } from 'react';
import { TabsContext } from './Tabs';

interface TabPanelsProps {
  children: ReactNode[];
}

export const TabPanels = ({ children }: TabPanelsProps) => {
  const { activeIndex } = React.useContext(TabsContext);

  return (
    <div data-testid={`tab-${activeIndex}`}>
      {children && children[activeIndex]}
    </div>
  );
};
