import React from 'react';
import {
  Loading,
  TabItem,
  TabItems,
  TabPanel,
  TabPanels,
  Tabs,
} from '../../components';
import { Login, Register } from '../../components';
import { Navigate, useLocation } from 'react-router-dom';
import { useApi } from '../../hooks';
import { QUERY_KEYS } from '../../constants';
import { useAuth } from '../../context';
import { useCookies } from 'react-cookie';
import { useQuery } from '@tanstack/react-query';

export const Home: React.FC = () => {
  const [cookies] = useCookies(['loggedIn']);
  const location = useLocation();
  const axios = useApi();

  const { state, dispatch } = useAuth();

  const query = useQuery(
    [QUERY_KEYS.USER],
    async () => {
      const response = await axios.get('/auth/me');
      return response.data;
    },
    {
      retry: 1,
      enabled: !!cookies.loggedIn,
      onSuccess: (data) => {
        console.log('SUCCESS_DATA', data);
        dispatch({ type: 'SET_USER', payload: data });
      },
    }
  );

  if (query.isLoading && cookies.loggedIn && !state.accessToken) {
    return <Loading />;
  }

  return state.accessToken && cookies.loggedIn ? (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  ) : (
    <Tabs className="max-w-md">
      <TabItems>
        <TabItem>Login</TabItem>
        <TabItem>Register</TabItem>
      </TabItems>
      <TabPanels>
        <TabPanel>
          <Login />
        </TabPanel>
        <TabPanel>
          <Register />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
