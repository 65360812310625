import { FeatureList } from '../components/FeatureList';
import { Loading } from '../../../components';
import { useGetPropertyFeatures } from '../../Properties/propertyApi';
import { useAppState } from '../../../context';
import { Link } from 'react-router-dom';

export const FeatureListPage = () => {
  const { state } = useAppState();
  const { data, isLoading } = useGetPropertyFeatures(
    state.selectedProperty?.id || ''
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <main className="py-10 px-4 sm:px-6">
      <div className="max-w-3xl border-b pb-4 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-2xl font-medium text-gray-900">Features</h1>
          <Link
            to="/feature/add"
            className="inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
          >
            Add
          </Link>
        </div>
      </div>
      <div className="mt-8 max-w-3xl lg:max-w-5xl">
        {data.length > 0 ? <FeatureList features={data} /> : null}
      </div>
    </main>
  );
};
