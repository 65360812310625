import { useNavigate } from 'react-router-dom';
import { ClockIcon } from '@heroicons/react/24/outline';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWithIcon } from '../../components';

export const AddTimeline = () => {
  const navigate = useNavigate();

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      {/* Main content */}
      <main className="py-10">
        {/* Page header */}
        <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="h-20 w-20">
              <div className="relative">
                <ClockIcon className="bg-white text-green-regular" />
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Add to Timeline
              </h1>
              <p className="text-sm font-medium text-gray-500">Fuller Acres</p>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="overflow-hidden bg-white py-5 px-4 shadow sm:rounded-md sm:px-6">
                  <form
                    onSubmit={handleOnSubmit}
                    className="space-y-8 divide-y divide-gray-200"
                  >
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div className="flex items-center justify-center pt-8">
                        <button
                          type="button"
                          className="mx-4 rounded-full border border-transparent bg-green-regular py-1.5 px-3 text-xs font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                        >
                          Journal
                        </button>
                        <button
                          type="button"
                          className="mx-4 rounded-full border border-green-regular bg-white py-1.5 px-3 text-xs font-medium text-green-regular shadow-sm hover:bg-green-regular hover:text-white focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                        >
                          Task
                        </button>
                        <button
                          type="button"
                          className="mx-4 rounded-full border border-green-regular bg-white py-1.5 px-3 text-xs font-medium text-green-regular shadow-sm hover:bg-green-regular hover:text-white focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                        >
                          Idea
                        </button>
                      </div>
                      <div className="pt-8">
                        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="street-address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Journal Entry
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={4}
                                name="comment"
                                id="comment"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-regular focus:ring-green-regular sm:text-sm"
                                defaultValue={''}
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Date
                            </label>
                            <div className="mt-1">
                              <DatePicker
                                onChange={() => console.log('changed')}
                                dateFormat="E, MMM d"
                                customInput={
                                  <DatePickerWithIcon
                                    onClick={() =>
                                      console.log('With Icon Changed')
                                    }
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-end">
                        <button
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};
