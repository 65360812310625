import { format } from 'date-fns';
import { JournalModel } from '../journal.models';

interface JournalItemProps {
  onClick: (journal: JournalModel) => void;
  journal: JournalModel;
}

export const JournalItem = ({ journal, onClick }: JournalItemProps) => {
  return (
    <li
      className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
      onClick={() => onClick(journal)}
      role="button"
    >
      <div className="flex items-center justify-between space-x-4">
        {/* Repo name and link */}
        <div className="min-w-0 space-y-3">
          <div className="flex items-center space-x-3">
            <h2 className="text-sm">{journal.title}</h2>
          </div>
          <p className="flex space-x-2 text-sm text-gray-500">
            <span>Task</span>
          </p>
        </div>
        {/* Repo meta info */}
        <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
          <div className="uppercase">{format(new Date(journal.date), 'MMM')}</div>
          <div className="mt-0">{format(new Date(journal.date), 'dd')}</div>
        </div>
      </div>
    </li>
  );
}