import React from 'react';

export const BuckIcon = () => {
  return (
    <div className="-mt-2.5 mr-1.5 h-3 w-3 shrink-0 text-gray-400">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        // width="786.000000pt"
        // height="1280.000000pt"
        viewBox="0 0 786 1280"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g
          transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
          fill="#9ca3af"
          stroke="none"
        >
          <path
            d="M7639 12784 c-16 -20 -15 -68 8 -244 25 -192 25 -595 -1 -735 -23
          -123 -68 -286 -99 -357 -26 -59 -87 -119 -143 -143 -79 -33 -205 -10 -242 44
          -10 14 -33 68 -51 120 -63 183 -140 314 -309 531 -238 302 -505 636 -524 653
          -30 26 -55 21 -66 -12 -15 -41 33 -153 134 -316 244 -392 374 -717 459 -1155
          46 -233 45 -308 -9 -655 -57 -365 -97 -578 -107 -568 -4 4 -15 49 -24 98 -38
          209 -140 453 -275 660 -79 121 -118 162 -144 152 -22 -9 8 -146 89 -417 157
          -526 179 -677 135 -933 -20 -121 -45 -197 -84 -254 -44 -65 -386 -450 -474
          -534 -48 -46 -140 -121 -205 -166 -135 -95 -128 -99 -98 68 121 656 116 919
          -24 1347 -41 128 -50 145 -78 163 -40 24 -39 24 -76 -7 -21 -17 -31 -35 -31
          -53 0 -15 20 -117 44 -227 47 -207 66 -343 66 -465 0 -102 -27 -269 -59 -370
          -16 -47 -135 -339 -266 -647 -236 -558 -238 -562 -264 -556 -14 4 -46 13 -70
          21 -25 7 -75 14 -113 14 -87 0 -151 -28 -237 -104 -35 -31 -88 -69 -119 -84
          -50 -24 -69 -27 -147 -27 -110 0 -145 15 -200 85 -80 101 -202 173 -310 184
          -98 10 -98 10 -136 77 -41 70 -71 164 -124 378 -113 463 -153 918 -140 1627 7
          398 7 401 -14 422 -12 12 -34 21 -51 21 -27 0 -33 -6 -51 -47 -109 -252 -157
          -547 -145 -888 10 -300 47 -747 75 -922 4 -29 5 -53 1 -53 -4 0 -45 17 -91 38
          -46 22 -147 63 -224 92 -592 224 -1139 510 -1340 701 -90 86 -193 219 -236
          304 -28 58 -30 67 -36 240 -11 333 23 620 118 1008 66 269 68 304 24 333 -22
          14 -27 14 -54 -2 -51 -30 -144 -252 -190 -454 -35 -156 -81 -494 -81 -605 0
          -69 -13 -49 -49 75 -76 263 -82 475 -19 670 31 98 72 172 168 310 48 69 207
          296 352 505 146 209 301 432 346 495 89 125 100 158 58 185 -19 13 -28 14 -51
          3 -32 -15 -291 -303 -497 -555 -168 -205 -207 -243 -246 -243 -37 0 -49 10
          -68 55 -25 60 -25 388 0 551 20 131 17 169 -14 179 -59 19 -89 -31 -125 -211
          -32 -164 -48 -280 -80 -579 -31 -298 -32 -299 -63 -359 -30 -56 -89 -104 -177
          -145 -93 -44 -120 -48 -140 -17 -52 80 -128 354 -144 526 -7 71 -10 289 -9
          565 3 417 2 453 -15 478 -26 40 -61 36 -112 -15 -31 -32 -47 -59 -60 -103 -66
          -229 -76 -333 -78 -800 -2 -305 0 -338 20 -440 30 -145 74 -275 165 -481 92
          -206 124 -293 182 -497 141 -486 337 -802 699 -1125 368 -327 474 -407 1042
          -783 253 -168 505 -341 560 -386 171 -138 425 -417 425 -466 0 -36 -22 -22
          -91 59 -91 106 -206 206 -308 268 -42 25 -216 111 -386 190 -171 79 -402 189
          -513 245 -112 55 -216 101 -231 101 -28 0 -59 -26 -80 -67 -6 -12 -16 -87 -23
          -166 -11 -129 -10 -157 5 -244 45 -253 208 -613 377 -831 148 -193 339 -322
          550 -375 71 -18 225 -37 306 -37 l51 0 66 -94 c76 -108 144 -227 184 -323 25
          -62 28 -81 31 -220 3 -104 -1 -186 -12 -260 -16 -110 -28 -150 -224 -803 -127
          -420 -175 -601 -199 -752 -9 -58 -22 -112 -27 -120 -12 -15 -306 -248 -391
          -310 -27 -19 -135 -88 -238 -151 -436 -269 -653 -464 -1097 -987 -458 -540
          -776 -916 -814 -963 l-39 -47 12 -123 c52 -516 204 -847 443 -960 388 -185
          1738 -391 3513 -536 204 -17 423 -36 487 -42 90 -8 125 -8 154 1 43 14 109 71
          130 112 8 15 39 46 70 69 79 58 101 110 110 254 12 204 -24 518 -82 711 -42
          138 -42 146 9 280 64 169 119 353 187 625 105 423 115 555 100 1369 -5 311
          -12 590 -15 622 l-5 56 38 20 c20 11 64 34 96 51 32 17 71 31 86 31 14 0 48 7
          73 16 42 14 59 30 148 142 144 181 139 167 100 309 -18 64 -73 247 -123 407
          -104 337 -159 544 -188 705 -25 142 -25 178 -1 266 11 39 22 106 26 150 4 44
          14 105 22 137 17 61 15 93 -12 242 -17 96 -17 100 1 113 10 7 25 11 33 8 7 -3
          56 -10 108 -15 271 -28 580 75 836 279 135 108 263 263 336 408 33 65 128 352
          167 501 39 151 65 363 48 390 -6 9 -21 18 -32 20 -22 3 -262 -81 -527 -185
          -365 -144 -635 -299 -892 -514 -45 -38 -85 -69 -90 -69 -7 0 -60 66 -66 82 -3
          9 520 483 798 722 254 219 391 355 595 589 171 197 232 280 321 440 183 329
          309 689 409 1169 39 189 68 271 200 558 220 482 248 590 237 915 -9 254 -42
          447 -112 664 -42 130 -72 165 -106 125z"
          />
        </g>
      </svg>
    </div>
  );
};
