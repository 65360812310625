import { useState } from 'react';
import { AxiosError } from 'axios';
import { useApi } from '../../hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateTreestandModel,
  FeatureNameModel,
  FeatureTypeModel,
} from './feature.models';
import { useAppState } from '../../context';

export const useAddTreestand = () => {
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const axios = useApi();
  const queryClient = useQueryClient();
  const { state } = useAppState();

  return {
    ...useMutation({
      mutationFn: async (data: CreateTreestandModel) => {
        return await axios.post<CreateTreestandModel>('/feature', data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['propertyFeatures', state.selectedProperty?.id],
          type: 'inactive',
        });
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }),
    apiError,
    setApiError,
  };
};

export const useGetFeatures = () => {
  const axios = useApi();

  return useQuery({
    queryKey: ['features'],
    queryFn: async () => {
      const results = await axios<FeatureNameModel[]>('/features');

      return results.data;
    },
  });
};

export const useGetFeatureTypes = () => {
  const axios = useApi();

  return useQuery({
    queryKey: ['featureTypes'],
    queryFn: async () => {
      const results = await axios<FeatureTypeModel[]>('/feature-types');

      return results.data;
    },
  });
};
