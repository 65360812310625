interface InputProps {
  id: string;
  type?: string;
  inputProps: Record<string, unknown>;
}

export const Input = ({ id, type = 'text', inputProps }: InputProps) => {
  return (
    <input
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-regular focus:ring-green-regular sm:text-sm"
      id={id}
      name={id}
      type={type}
      {...inputProps}
    />
  );
}