import React, { FormEvent } from 'react';

interface FormProps {
  children: React.ReactNode;
  name: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export const FormRoot = ({ children, name, onSubmit }: FormProps) => {
  return (
    <form
      name={name}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <div className="mt-6 grid grid-cols-1 gap-0 gap-x-4 sm:grid-cols-6">
        {children}
      </div>
    </form>
  );
};
