import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useApi } from 'hooks';
import { CreatePropertyModel, PropertyModel } from './property.model';
import { useEffect, useState } from 'react';
import { useAppState, useAuth } from '../../context';

export const useGetOwnerProperties = (
  userId: string,
  select?: (data: PropertyModel[]) => PropertyModel[]
) => {
  const axios = useApi();

  return useQuery({
    queryKey: ['properties', userId],
    queryFn: async () => {
      const properties = await axios.get<PropertyModel[]>('/property');

      // If there are no properties, make sure there is no local storage set
      // for selected property.
      if (properties.data.length < 1) {
        localStorage.removeItem('selectedProperty');
      }
      return properties.data;
    },
    select,
    retry: 1,
    enabled: !!userId,
  });
};

export const useGetDefaultProperty = () => {
  const axios = useApi();
  const queryClient = useQueryClient();
  const { dispatch } = useAppState();
  const { state } = useAuth();
  const {
    data: properties,
    isLoading,
    isError,
  } = useGetOwnerProperties(state.user?.id || '');
  const [localDefault, setLocalDefault] = useState(null);

  const { mutate } = useMutation({
    mutationFn: (data: { defaultProperty: string }) => {
      return axios.patch('/user', data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
    },
  });

  // If local state is set.
  useEffect(() => {
    const property = localStorage.getItem('selectedProperty');

    if (!property) {
      return;
    }

    const newProp = property ? JSON.parse(property) : {};
    setLocalDefault(newProp);
  }, [dispatch]);

  useEffect(() => {
    if (localDefault && !isLoading && !isError) {
      dispatch({ type: 'SET_SELECTED_PROPERTY', payload: localDefault });
      return;
    }

    if (!isLoading && !isError) {
      const filtered = properties?.filter((property) => {
        return property.id === state.user?.defaultProperty;
      });

      if (filtered.length > 0) {
        dispatch({ type: 'SET_SELECTED_PROPERTY', payload: filtered[0] });
        return;
      }

      if (properties?.length > 0) {
        dispatch({ type: 'SET_SELECTED_PROPERTY', payload: properties[0] });
        mutate({ defaultProperty: properties[0].id });
        localStorage.setItem('selectedProperty', JSON.stringify(properties[0]));
      }
    }
  }, [properties, dispatch, isError, isLoading, localDefault, state, mutate]);
};

export const useCreateProperty = () => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const axios = useApi();

  return {
    ...useMutation({
      mutationFn: (data: CreatePropertyModel): Promise<AxiosResponse> => {
        return axios.post<CreatePropertyModel>('/property', data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['properties']);
        queryClient.invalidateQueries(['user']);
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }),
    apiError,
    setApiError,
  };
};

export const useGetOwnerPropertyByPropertyId = (propertyId: string) => {
  const axios = useApi();

  return useQuery({
    queryKey: ['property', propertyId],
    queryFn: async () => {
      const properties = await axios.get<PropertyModel>(
        `/property/${propertyId}`
      );

      return properties.data;
    },
  });
};

export const useGetPropertyFeatures = (propertyId: string) => {
  const axios = useApi();

  return useQuery({
    queryKey: ['propertyFeatures', propertyId],
    queryFn: async () => {
      const features = await axios.get(`/property-features/${propertyId}`);

      return features.data;
    },
    enabled: !!propertyId,
  });
};
