import React from 'react';
import { cn } from '../../../../utils';

interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'button';
  onClick?: () => void;
}

export const Button = ({
  children,
  className,
  disabled = false,
  type = 'submit',
  onClick,
}: ButtonProps) => {
  if (type === 'submit') {
    return (
      <button
        disabled={disabled}
        type={type}
        className={cn({
          'inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2':
            true,
          'bg-gray-400': disabled,
          [`${className}`]: true,
        })}
      >
        {children}
      </button>
    );
  } else {
    return (
      <button
        disabled={disabled}
        type={type}
        className={cn({
          'inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2':
            true,
          'bg-gray-400': disabled,
          [`${className}`]: true,
        })}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {children}
      </button>
    );
  }
};
