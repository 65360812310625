import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';

import { useApi } from 'hooks';
import { useAppState } from 'context';

import logo from '../../../assets/logo.jpg';
import { AxiosError } from 'axios';
import { TabsContext } from 'components/Tabs';
import { FormInputError } from '../FormInputError';
import { ApiFormErrors } from '../ApiFormErrors';

const schema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(4, { message: 'Password must be 4 or more characters' })
    .max(20, { message: 'Password must be 20 or fewer characters' }),
});

export type RegisterValidationSchema = z.infer<typeof schema>;

export const Register = () => {
  const axios = useApi(false);
  const { setActiveIndex } = useContext(TabsContext);
  const { dispatch } = useAppState();
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<RegisterValidationSchema>({
    criteriaMode: 'firstError',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const { mutate: registerUser, isLoading } = useMutation(
    (userData: RegisterValidationSchema) => {
      setApiError(null);
      return axios.post('/auth/register', userData);
    },
    {
      onSuccess: () => {
        setActiveIndex(0);
        dispatch({ type: 'SET_REGISTRATION_COMPLETED', payload: true });
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }
  );

  const onSubmit = handleSubmit(async (data) => {
    registerUser(data);
  });

  return (
    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex items-center justify-center">
          <img src={logo} alt="huntscribe" className="w-60" />
        </div>
        <h1 className="text-l mt-4 text-center text-green-regular">
          Register your account.
        </h1>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {apiError ? (
            <div className="mb-4">
              <ApiFormErrors apiError={apiError} />
            </div>
          ) : null}
          <form onSubmit={onSubmit} className="space-y-6">
            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="firstName"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  {...register('firstName')}
                  autoComplete="firstName"
                  className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                  id="firstName"
                  required
                  type="text"
                />
                {errors.firstName?.message && (
                  <FormInputError message={errors.firstName.message} />
                )}
              </div>
            </div>
            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="lastName"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  {...register('lastName')}
                  autoComplete="lastName"
                  className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                  id="lastName"
                  required
                  type="text"
                />
                {errors.lastName?.message && (
                  <FormInputError message={errors.lastName.message} />
                )}
              </div>
            </div>
            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="email"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  {...register('email')}
                  autoComplete="email"
                  className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                  id="email"
                  required
                  type="email"
                />
                {errors.email?.message && (
                  <FormInputError message={errors.email.message} />
                )}
              </div>
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="password"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  {...register('password')}
                  autoComplete="current-password"
                  className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                  id="password"
                  required
                  type="password"
                />
                {errors.password?.message && (
                  <FormInputError message={errors.password.message} />
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                disabled={isLoading}
              >
                {`${!isLoading ? 'Register' : 'Loading...'}`}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
