import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useApi } from '../../../hooks';
import { useAuth } from 'context/AuthContext';
import logo from '../../../assets/logo.jpg';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppState } from 'context';
import { useState } from 'react';
import { ApiFormErrors } from '../ApiFormErrors';
import { FormInputError } from '../FormInputError';

type FromState = {
  from: {
    pathname: string;
  };
};

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'Password is required.' })
    .email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(4, { message: 'Password must be 4 or more characters' })
    .max(20, { message: 'Password must be 20 or fewer characters' }),
});

type ValidationSchema = z.infer<typeof schema>;

export const Login = () => {
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const { state } = useAppState();
  const [_, setCookie] = useCookies();
  const { dispatch } = useAuth();
  const axios = useApi(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locale = location.state as FromState;

  const from = locale?.from?.pathname || '/dashboard';

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ValidationSchema>({
    defaultValues: {
      email: import.meta.env.DEV ? 'eric@test.com' : '',
      password: import.meta.env.DEV ? 'Password' : '',
    },
    resolver: zodResolver(schema),
  });

  const { mutate: loginUser, isLoading } = useMutation(
    (userData: ValidationSchema) => {
      setApiError(null);
      return axios.post('/auth/login', userData);
    },
    {
      onSuccess: (result: AxiosResponse) => {
        const { accessToken = '', user } = result.data;
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
        dispatch({
          type: 'SET_USER',
          payload: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            loggedIn: true,
            defaultProperty: user.defaultProperty,
          },
        });
        setCookie('loggedIn', 'true', { maxAge: 24 * 60 * 60 });
        navigate(from, { replace: true });
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }
  );

  const onSubmit = handleSubmit(async (data) => {
    loginUser(data);
  });

  return (
    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex items-center justify-center">
          <img src={logo} alt="huntscribe" className="w-60" />
        </div>
        {state.status.registrationCompleted ? (
          <h1 className="text-l mt-4 text-center text-green-regular">
            Registration successful! You can now login!
          </h1>
        ) : (
          <h1 className="text-l mt-4 text-center text-green-regular">
            Sign into your account
          </h1>
        )}
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {apiError ? (
            <div className="mb-4">
              <ApiFormErrors apiError={apiError} />
            </div>
          ) : null}
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="email"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  {...register('email')}
                  autoComplete="email"
                  className="focus:ring-text-regular block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none sm:text-sm"
                  id="email"
                  required
                  type="email"
                />
                {errors.email?.message && (
                  <FormInputError message={errors.email.message} />
                )}
              </div>
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="password"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  {...register('password')}
                  autoComplete="current-password"
                  className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                  id="password"
                  required
                  type="password"
                />
                {errors.password?.message && (
                  <FormInputError message={errors.password.message} />
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                disabled={isLoading}
              >
                {`${!isLoading ? 'Sign in' : 'Loading...'}`}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
