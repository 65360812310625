import axios, { AxiosInstance } from 'axios';

const Client = (guarded = true): AxiosInstance => {
  const client = axios.create({
    baseURL: import.meta.env.DEV
      ? import.meta.env.VITE_API_URL
      : 'https://api.huntscribe.app/api/v1',
    withCredentials: true,
  });

  // A basic interceptor for redirecting 401 HTTP responses.
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status) {
        window.location.href = '/';
        return;
      } else {
        return Promise.reject(error);
      }
    }
  );

  if (guarded) {
    client.defaults.withCredentials = true;
  }

  return client;
};

export { Client };
