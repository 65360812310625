import React from 'react';
import Map, { Marker, GeolocateControl, useMap } from 'react-map-gl';
import { useEffect, useState } from 'react';
import { Loading } from '../Loading';

const TOKEN = import.meta.env.VITE_MAPBOX_KEY; // Set your mapbox token here

type LatLng = {
  lat: number;
  lng: number;
};

interface CustomMapProps {
  onChange?: (data: LatLng) => void;
  initialLocation: LatLng;
  mapId: string;
  customStyles?: React.CSSProperties;
}

export const CustomMap = ({
  onChange,
  initialLocation,
  mapId,
  customStyles = {},
}: CustomMapProps) => {
  const { customMap } = useMap();
  const [center, setCenter] = useState<LatLng | null>(null);
  const [markers, setMarker] = useState<LatLng[]>([]);

  useEffect(() => {
    setCenter(initialLocation);
  }, [initialLocation]);

  useEffect(() => {
    if (!customMap || !center) {
      return;
    }

    customMap.flyTo({ center: [center.lng, center.lat] });
  });

  if (!center) {
    return <Loading />;
  }

  return (
    <div style={{ height: '800px', width: '100%', ...customStyles }}>
      <Map
        id={mapId}
        initialViewState={{
          longitude: initialLocation.lng,
          latitude: initialLocation.lat,
          zoom: 17,
        }}
        cursor="crosshair"
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken={TOKEN}
        onClick={(data) => {
          if (data.target.boxZoom) {
            setMarker(() => {
              const { lngLat } = data;
              return [lngLat];
            });

            if (onChange) {
              onChange(data.lngLat);
            }
          }
        }}
      >
        <GeolocateControl position="top-left" />
        {markers.length > 0
          ? markers.map((marker, id) => (
              <Marker
                anchor="bottom"
                key={id}
                latitude={marker.lat}
                longitude={marker.lng}
                onClick={(data) => {
                  console.log('MARKER', data);
                  data.originalEvent.stopPropagation();
                }}
              >
                <img
                  style={{ cursor: 'pointer' }}
                  src="https://img.icons8.com/ios-filled/24/F7F808/visit.png"
                />
              </Marker>
            ))
          : null}
      </Map>
    </div>
  );
};
