import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { CreatePropertyModel } from '../property.model';
import { useCurrentUser } from 'hooks';
import { useCreateProperty } from '../propertyApi';
import { FormInputError } from '../../../components/Forms/FormInputError';
import { ApiFormErrors } from '../../../components/Forms/ApiFormErrors';

export const schema = z.object({
  name: z.string().min(1, { message: 'Enter a property name.' }),
  lat: z.string().min(1, { message: 'Enter latitude.' }),
  lng: z.string().min(1, { message: 'Enter longitude.' }),
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});

export type ValidationSchema = z.infer<typeof schema>;

export const AddPropertyForm = () => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      lat: '',
      lng: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    },
  });

  const { data: currentUser } = useCurrentUser();
  const { mutate, apiError, setApiError, isSuccess, isLoading } =
    useCreateProperty();

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    if (!currentUser?.id) {
      return;
    }

    const property: CreatePropertyModel = {
      ...data,
      lat: parseFloat(data.lat),
      lng: parseFloat(data.lng),
      owner: currentUser.id,
    };

    setApiError(null);

    mutate(property);
  };

  return (
    <form
      data-testid="add-property"
      className="space-y-8 divide-y divide-gray-200"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        {apiError ? (
          <div>
            <ApiFormErrors apiError={apiError} />
          </div>
        ) : null}
        {isSuccess ? (
          <div>
            <div className="mt-4">
              <p>Property Added!</p>
            </div>
          </div>
        ) : null}
        <div className="mt-6 grid grid-cols-1 gap-0 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="name"
            >
              Name
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('name')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="name"
                required
              />
              {errors.name?.message && (
                <FormInputError message={errors.name.message} />
              )}
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-gray-700 sm:mt-px"
              htmlFor="lat"
            >
              Latitude
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('lat')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="lat"
                required
              />
              {errors.lat?.message && (
                <FormInputError message={errors.lat.message} />
              )}
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-gray-700 sm:mt-px"
              htmlFor="lng"
            >
              Longitude
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('lng')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="lng"
                required
              />
              {errors.lng?.message && (
                <FormInputError message={errors.lng.message} />
              )}
            </div>
          </div>
          <div className="sm:col-span-6">
            <label
              className="block text-sm font-medium text-gray-700 sm:mt-px"
              htmlFor="street"
            >
              Street
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('street')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="street"
              />
              {errors.street?.message && (
                <FormInputError message={errors.street.message} />
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              className="block text-sm font-medium text-gray-700 sm:mt-px"
              htmlFor="city"
            >
              City
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('city')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="city"
              />
              {errors.city?.message && (
                <FormInputError message={errors.city.message} />
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              className="block text-sm font-medium text-gray-700 sm:mt-px"
              htmlFor="state"
            >
              State
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('state')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="state"
              />
              {errors.state?.message && (
                <FormInputError message={errors.state.message} />
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="zip"
              className="block text-sm font-medium text-gray-700 sm:mt-px"
            >
              Zip
            </label>
            <div className="mt-1 min-h-[75px]">
              <input
                {...register('zip')}
                className="block w-full rounded-md border border-gray-300 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                id="zip"
              />
              {errors.zip?.message && (
                <FormInputError message={errors.zip.message} />
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end sm:col-span-6">
          <button
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
            type="button"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            data-testid="submitButton"
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-accent focus:ring-offset-2"
          >
            {`${!isLoading ? 'Save' : 'Loading...'}`}
          </button>
        </div>
      </div>
    </form>
  );
};
