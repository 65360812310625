import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { useGetJournalTypes } from '../../pages/Timeline/api';

interface JournalTypesProps {
  value: string;
  onChange: () => void;
}

export default function JournalTypes({ value, onChange }: JournalTypesProps) {
  const { data: journalTypes, isLoading: journalTypesLoading } =
    useGetJournalTypes();

  if (!value || !journalTypes || journalTypesLoading) {
    return null;
  }

  return (
    <div>
      <RadioGroup value={value} onChange={onChange} className="mt-2 mr-4">
        <RadioGroup.Label className="sr-only">
          {' '}
          Choose a journal type{' '}
        </RadioGroup.Label>
        <div className="flex gap-3">
          {journalTypes.map((option) => (
            <RadioGroup.Option
              key={option.name}
              value={option.name}
              className={({ active, checked }) =>
                classNames(
                  active ? 'ring-2 ring-green-regular ring-offset-2' : '',
                  checked
                    ? 'bg-green-regular text-white hover:bg-green-dark'
                    : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                  'flex cursor-pointer items-center justify-center whitespace-nowrap rounded-md py-1 px-2 text-xs uppercase sm:flex-1'
                )
              }
            >
              <RadioGroup.Label as="span">{option.niceName}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
