import React, { forwardRef } from 'react';

type DatePickerWithIconProps = {
  onClick: (e: React.MouseEvent) => void;
  value?: string;
  placeholder?: string;
};

export const DatePickerWithIcon = forwardRef<
  HTMLLabelElement,
  DatePickerWithIconProps
>((props, ref) => {
  return (
    <div className="flex items-center">
      <label
        className="mr-6 flex items-baseline text-sm font-semibold text-green-regular"
        onClick={props.onClick}
        ref={ref}
      >
        {props.value || props.placeholder}
      </label>
      <button type="button" onClick={props.onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 fill-white text-green-regular"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </button>
    </div>
  );
});
DatePickerWithIcon.displayName = 'DatePickerWithIcon';
