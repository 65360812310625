import { useEffect } from 'react';

import { useAuth } from 'context';
import { useRefreshToken } from 'hooks/useRefreshToken';
import { apiClient } from '../../services/apiClient';

export const useApi = (secure = true) => {
  const { state } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    if (!secure) {
      return;
    }

    apiClient.interceptors.request.use(
      (config) => {
        if (
          config?.headers !== undefined &&
          !config?.headers['Authorization'] &&
          state?.accessToken
        ) {
          config.headers['Authorization'] = `Bearer ${state.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    apiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest.sent) {
          const token = await refresh();
          return apiClient({
            ...prevRequest,
            headers: {
              ...prevRequest.headers,
              Authorization: `Bearer ${token?.data?.accessToken}`,
            },
            sent: true,
          });
        }
        return Promise.reject(error);
      }
    );
  }, [refresh, secure, state]);

  return apiClient;
};
