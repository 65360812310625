import { JournalModel } from '../journal.models';
import { JournalItem } from './journalItem';
import { useState } from 'react';
import { Modal } from '../../../components/Modal';
import { CurrentJournalForm } from './currentJournalForm';

interface JournalListProps {
  journals: JournalModel[];
}

export const JournalList = ({ journals }: JournalListProps) => {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<JournalModel | null>(null);

  const handleClick = (item: JournalModel) => {
    setOpen(true);
    setCurrentItem(item);
  };

  return (
    <>
      <ul
        role="list"
        className="mt-0 divide-y divide-gray-200 border-x border-b border-gray-200"
      >
        {journals.map((journal, i) => {
          return (
            <JournalItem key={i} journal={journal} onClick={handleClick} />
          );
        })}
      </ul>
      {open && currentItem ? (
        <Modal open={open} setOpen={setOpen}>
          <CurrentJournalForm journal={currentItem} close={setOpen} />
        </Modal>
      ) : null}
    </>
  );
};
