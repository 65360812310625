import React from 'react';
import classNames from 'classnames';
import { TabsContext } from './Tabs';
import { TabItemsContext } from './TabItems';

interface TabItemProps {
  children: React.ReactNode;
  disabled?: boolean;
}

export const TabItem = ({ children, disabled = false }: TabItemProps) => {
  const { activeIndex, setActiveIndex } = React.useContext(TabsContext);
  const { tabItemsIndex } = React.useContext(TabItemsContext);
  const tabClassNames = classNames({
    'text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer':
      true,
    'tab-disabled': disabled,
    'tab-active': activeIndex === tabItemsIndex,
  });
  return (
    <div
      className={tabClassNames}
      onClick={() => setActiveIndex(tabItemsIndex)}
    >
      <>
        {children}
        <span
          aria-hidden="true"
          className={classNames(
            activeIndex === tabItemsIndex
              ? 'bg-green-regular'
              : 'bg-transparent',
            'absolute inset-x-0 bottom-0 h-0.5'
          )}
        />
      </>
    </div>
  );
};
