import { Loading } from 'components';
import { useParams } from 'react-router-dom';
import { EditPropertyForm } from '../EditPropertyForm';
import { useGetOwnerPropertyByPropertyId } from '../propertyApi';

export const EditProperty = () => {
  const { id = '' } = useParams();
  const {
    data: property,
    isLoading,
    isError,
  } = useGetOwnerPropertyByPropertyId(id);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>It looks like there was an error.</p>;
  }

  return (
    <main className="py-10 px-8">
      <div className="max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-medium text-gray-900">
              Edit Property
            </h1>
          </div>
        </div>
      </div>
      <div className="mt-8 max-w-3xl lg:max-w-5xl">
        <EditPropertyForm property={property} />
      </div>
    </main>
  );
};
