import React, { useState } from 'react';
import { cn } from '../../utils';

export interface tabsContextInterface {
  activeIndex: number;
  setActiveIndex: React.Dispatch<number>;
}

export const TabsContext = React.createContext({} as tabsContextInterface);

interface TabsProps {
  children: React.ReactNode;
  className?: string;
}

export const Tabs = ({ children, className }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={cn({ 'mx-auto pt-16': true, [`${className}`]: className })}>
      <div className="tabs shadow-lg">
        <TabsContext.Provider value={{ activeIndex, setActiveIndex }}>
          {children}
        </TabsContext.Provider>
      </div>
    </div>
  );
};
