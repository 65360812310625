import { Route, Routes } from 'react-router-dom';
import {
  Dashboard,
  Home,
  AddHunt,
  Timeline,
  AddTimeline,
  Hunts,
  Properties,
  Map,
} from './pages';
import { Profile } from 'pages/User';
import { Layout } from 'components/Layout';
import { RequireAuth } from 'components/RequireAuth';
import { EditProfile } from 'pages/User/EditProfile';
import { AddProperty } from 'pages/Properties/AddProperty';
import { EditProperty } from 'pages/Properties/EditProperty';
import { RequireProperty } from './components/RequireProperty';
import { AddFeature } from './pages/AddFeature';
import { FeatureListPage } from './pages/Features';
import { FeatureDetailsPage } from './pages/Features/ui/FeatureDetailsPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route element={<Layout />}>
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route element={<RequireProperty />}>
            <Route path="/hunts" element={<Hunts />} />
            <Route path="/hunts/add" element={<AddHunt />} />
            <Route path="/journal" element={<Timeline />} />
            <Route path="/journal/add" element={<AddTimeline />} />
            <Route path="/map" element={<Map />} />
            <Route path="/features" element={<FeatureListPage />} />
            <Route path="/feature/add" element={<AddFeature />} />
            <Route path="/feature/:id" element={<FeatureDetailsPage />} />
          </Route>
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/user/profile/edit" element={<EditProfile />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/add" element={<AddProperty />} />
          <Route path="/properties/:id" element={<EditProperty />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
