import { forwardRef, useState } from 'react';

type Ref = HTMLInputElement;

interface IProps {
  labelText?: string;
  onChange?: (e: string | number) => void;
}

export const InputCounter = forwardRef<Ref, IProps>(function InputCounter(
  { labelText, ...props },
  ref
) {
  const [value, setValue] = useState<number | string | undefined>(0);

  return (
    <div className="w-full px-4">
      <label htmlFor="counter" className="text-base font-normal text-gray-900">
        {labelText}
      </label>
      <div className="mt-1 flex items-center justify-between md:block lg:flex">
        <input
          {...props}
          aria-label="input-counter"
          className="block w-1/3 rounded-md border border-gray-300 p-3 text-center text-2xl text-gray-500 shadow-sm focus:border-green-regular focus:ring-green-regular sm:text-sm"
          data-testid="input-counter"
          id="counter"
          onChange={(e) => {
            setValue(Number(e.target.value));
            props.onChange && props.onChange(e.target.value);
          }}
          ref={ref}
          type="number"
          value={value}
        />
        <div className="flex flex-col">
          <div>
            <button
              data-testid="increment"
              onClick={() => {
                setValue(Number(value) + 1);
                props.onChange && props.onChange(Number(value) + 1);
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 fill-green-regular"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              data-testid="decrement"
              onClick={() => {
                if (value && value >= 0) {
                  setValue(Number(value) - 1);
                  props.onChange && props.onChange(Number(value) - 1);
                }
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 fill-green-regular"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

