import { useContext } from 'react';
import { createCtx } from 'services/createContext';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  loggedIn: boolean;
  defaultProperty?: string;
}

const initialState = { accessToken: '', user: null };
type AppState = {
  accessToken: string;
  user: User | null;
};

type Action =
  | { type: 'SET_ACCESS_TOKEN'; payload: string }
  | { type: 'SET_USER'; payload: User };

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    default:
      throw new Error();
  }
}

const [ctx, AuthProvider] = createCtx(reducer, initialState);

const useAuth = () => {
  return useContext(ctx);
};

export { useAuth, AuthProvider };
