import React, { Fragment, SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import { Transition, Dialog } from '@headlessui/react';
import { HomeIcon, ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';

import logoIcon from '../../../assets/logo-icon2.jpg';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  { name: 'Journal', href: '/journal', icon: ClockIcon, current: false },
  { name: 'Hunts', href: '/hunts', icon: ClockIcon, current: false },
  { name: 'Map', href: '/map', icon: ClockIcon, current: false },
  { name: 'Features', href: '/features', icon: ClockIcon, current: false },
];

interface Props {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: React.Dispatch<SetStateAction<boolean>>;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const MobileMenu = ({ mobileMenuOpen, setMobileMenuOpen }: Props) => (
  <Transition.Root show={mobileMenuOpen} as={Fragment}>
    <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-40 flex">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-green-regular pt-5 pb-4">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-1 right-0 -mr-14 p-1">
                <button
                  type="button"
                  className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Close sidebar</span>
                </button>
              </div>
            </Transition.Child>
            <div className="flex shrink-0 items-center px-4">
              <img src={logoIcon} alt="huntscribe" className="h-16" />
            </div>
            <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
              <nav className="flex h-full flex-col">
                <div className="space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) => {
                        return classNames(
                          isActive
                            ? 'bg-green-dark text-white'
                            : 'text-white hover:bg-green-dark hover:text-white',
                          'group flex items-center rounded-md py-2 px-3 text-sm font-medium'
                        );
                      }}
                    >
                      <item.icon
                        className={classNames('mr-3 h-6 w-6')}
                        aria-hidden="true"
                      />
                      <span>{item.name}</span>
                    </NavLink>
                  ))}
                </div>
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="w-14 shrink-0" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
