import React from 'react';

interface tabItemsContextInterface {
  tabItemsIndex: number;
}

interface TabItemsProps {
  children: React.ReactNode;
}

export const TabItemsContext = React.createContext(
  {} as tabItemsContextInterface
);

export const TabItems: React.FC<TabItemsProps> = ({ children }) => {
  const wrappedChildren = React.Children.map(children, (child, i) => {
    return (
      <TabItemsContext.Provider value={{ tabItemsIndex: i }}>
        {child}
      </TabItemsContext.Provider>
    );
  });

  return (
    <div className="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow">
      {wrappedChildren}
    </div>
  );
};
