import { ClockIcon } from '@heroicons/react/24/outline';
import { Loading } from 'components';
import { useCurrentUser } from 'hooks';
import { Link } from 'react-router-dom';
import { useAppState } from '../../../context';

export const Profile = () => {
  const { state } = useAppState();
  const { data, isLoading, isError } = useCurrentUser();

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isError) {
    return <h2>It looks like an error occurred.</h2>;
  }

  return (
    <>
      {/* Main content */}
      <main className="py-4">
        {/* Page header */}
        <div className="mt-2 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1">
            {/* Description list*/}
            <div className="overflow-hidden bg-white pb-10 shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-5">
                    <div>
                      <h1 className="text-2xl font-bold text-gray-900">
                        Profile
                      </h1>
                      <p className="text-sm font-medium text-gray-500">
                        Fuller Acres
                      </p>
                    </div>
                  </div>
                  <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                    <Link
                      to="edit"
                      className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      First Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.firstName}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Last Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.lastName}
                    </dd>
                  </div>
                  {state.selectedProperty?.name ? (
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Default Property
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {state.selectedProperty?.name}
                      </dd>
                    </div>
                  ) : null}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.email}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
