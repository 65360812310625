import * as z from 'zod';
import { CreateJournalModel } from '../journal.models';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ApiFormErrors } from '../../../components/Forms/ApiFormErrors';
import { useEffect } from 'react';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { DatePickerWithIcon, ErrorSummary } from '../../../components';
import { useAddJournal } from '../api';
import { useAppState, useAuth } from '../../../context';
import { XMarkIcon } from '@heroicons/react/24/outline';
import JournalTypes from '../../../components/JournalTypes';

export const schema = z.object({
  title: z.string().min(1, { message: 'Content is required' }),
  typeName: z.string().min(1, { message: 'Type is required' }),
  description: z.string(),
  date: z.date(),
});

export type ValidationSchema = z.infer<typeof schema>;

interface AddJournalFormProps {
  modalOpen: (open: boolean) => void;
}

export const AddJournalForm = ({ modalOpen }: AddJournalFormProps) => {
  const { state } = useAuth();
  const { state: appState } = useAppState();
  const { mutate, apiError, setApiError, isSuccess, isLoading } =
    useAddJournal();

  const {
    reset,
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: '',
      date: new Date(),
      typeName: 'general',
    },
  });

  const onSubmit = (data: ValidationSchema) => {
    if (!state.user?.id || !appState.selectedProperty) {
      return;
    }

    const journal: CreateJournalModel = {
      title: data.title,
      description: data.description,
      date: data.date,
      user: state.user.id,
      typeName: data.typeName,
      property: appState.selectedProperty.id,
    };

    setApiError(null);

    mutate(journal);
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <div className="sm:px6 lg:px8 flex w-full flex-col justify-center pb-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="text-l mt-4 text-center text-black">
          Add Journal Entry
        </h1>
        {isLoading ? (
          <p className="text-center">Adding journal...</p>
        ) : (
          <div className="text-center">
            {isSuccess ? <p>Journal Added!</p> : null}
          </div>
        )}
      </div>
      <div className="sm:2-full mt-8 w-full sm:mx-auto">
        <div className="mb-4">
          <ErrorSummary errors={errors} />
          {apiError ? <ApiFormErrors apiError={apiError} /> : null}
        </div>
        <div className="relative bg-white">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200">
              <label htmlFor="entry" className="sr-only">
                Entry
              </label>
              <input
                {...register('title')}
                type="text"
                className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                placeholder="Title"
                required
              />
              <label htmlFor="description" className="sr-only">
                Description
              </label>
              <textarea
                {...register('description')}
                rows={2}
                id="description"
                className="block w-full resize-none border-0 py-0 placeholder:text-gray-500 focus:ring-0 sm:text-sm"
                placeholder="Write some details..."
              />

              {/* Spacer element to match the height of the toolbar */}
              <div aria-hidden="true">
                <div className="py-2">
                  <div className="h-9" />
                </div>
                <div className="h-px" />
                <div className="py-2">
                  <div className="py-px">
                    <div className="h-9" />
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute inset-x-px bottom-0">
              <div className="flex flex-nowrap justify-end space-x-2 p-2 sm:px-3">
                <Controller
                  name="typeName"
                  control={control}
                  render={({ field }) => (
                    <JournalTypes
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <div className="shrink-0">
                      <DatePicker
                        popperPlacement="left"
                        onChange={field.onChange}
                        dateFormat="MMM, d"
                        ref={(
                          elem: ReactDatePicker & { input: HTMLLabelElement }
                        ) => {
                          elem && field.ref(elem.input);
                        }}
                        selected={field.value}
                        customInput={
                          <DatePickerWithIcon onClick={field.onChange} />
                        }
                      />
                    </div>
                  )}
                />
              </div>
              <div className="flex items-center justify-between space-x-3 border-t border-gray-200 p-2 sm:px-3">
                <div className="flex">
                  <button
                    type="button"
                    className="group inline-flex items-center rounded-md border border-red-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                    onClick={() => modalOpen(false)}
                  >
                    <span className="sr-only">Cancel</span>
                    <XMarkIcon className="h-5 w-5 fill-red-600 text-red-700 group-hover:text-white" />
                  </button>
                </div>
                <div className="shrink-0">
                  <button
                    type="submit"
                    className="inline-flex items-center rounded-md border border-transparent bg-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
