import React, { forwardRef } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { useGeolocation } from '../../hooks';

interface LocationProps {
  lat: number | string;
  lng: number | string;
  handleOnChange: (e: { lng: number | string; lat: number | string }) => void;
}

export interface ICoords {
  lat: number | string;
  lng: number | string;
}

interface IViewPort {
  zoom: number;
}

export const Location = forwardRef<HTMLInputElement, LocationProps>(
  ({ lat, lng, handleOnChange }, ref) => {
    const [when, setWhen] = React.useState(false);
    const [coords, setCoords] = React.useState<ICoords>({ lat, lng });
    const [viewport, setViewport] = React.useState<IViewPort>({
      zoom: 15,
    });
    const { geo, isLoading } = useGeolocation({ when });

    React.useEffect(() => {
      if (geo?.lng && geo?.lat) {
        setCoords({ lat: geo.lat, lng: geo.lng });
        handleOnChange({ lng: geo.lng, lat: geo.lat });
      }
    }, [geo?.lng, geo?.lat, handleOnChange]);

    return (
      <div className="location my-6">
        {geo ? (
          <div style={{ height: '200px' }}>
            <ReactMapGL
              {...viewport}
              latitude={Number(coords.lat)}
              longitude={Number(coords.lng)}
              mapStyle="mapbox://styles/mapbox/outdoors-v11"
            >
              <Marker
                longitude={Number(coords.lng)}
                latitude={Number(coords.lat)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </Marker>
            </ReactMapGL>
          </div>
        ) : (
          <div className="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Location</h3>
            <p className="mt-1 text-sm text-gray-500">
              Use your current location or add in coordinates.
            </p>
          </div>
        )}
        <div className="mt-6 flex flex-col">
          <div className="location-container">
            <div className="location__value">
              <div className="text-left text-xs font-normal text-gray-900">
                Latitude
              </div>
              <input
                onChange={(e) => {
                  console.log(e.target.value);
                  setCoords((prevState) => ({
                    ...prevState,
                    lat: e.target.value,
                  }));
                  handleOnChange({ lng: coords.lng, lat: e.target.value });
                }}
                ref={ref}
                type="number"
                name="coords[lat]"
                placeholder="latitude"
                className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                value={`${coords.lat ?? ''}`}
              />
            </div>
            <div className="location__value mt-6">
              <div className="text-left text-xs font-normal text-gray-900">
                Longitude
              </div>
              <input
                onChange={(e) => {
                  setCoords((prevState) => ({
                    ...prevState,
                    lng: e.target.value,
                  }));
                  handleOnChange({ lat: coords.lat, lng: e.target.value });
                }}
                ref={ref}
                type="number"
                name="coords[lng]"
                placeholder="longitude"
                className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-500 shadow-sm placeholder:text-gray-400 focus:border-green-regular focus:outline-none focus:ring-green-regular sm:text-sm"
                value={`${coords.lng ?? ''}`}
              />
            </div>
          </div>
          <button
            type="button"
            className="mb-4 mt-6 inline-flex items-center rounded-md border border-transparent bg-green-regular p-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
            onClick={() => setWhen(true)}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {isLoading ? 'Getting Location Details...' : 'Use Current Location'}
          </button>
        </div>
      </div>
    );
  }
);
Location.displayName = 'Location';
