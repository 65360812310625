import { PencilIcon } from '@heroicons/react/24/outline';
import { Loading } from 'components';
import { Link } from 'react-router-dom';
import { useGetOwnerProperties } from './propertyApi';
import { useAuth } from '../../context';

export const Properties = () => {
  const { state } = useAuth();
  const { data, isLoading, isError } = useGetOwnerProperties(
    state.user?.id || ''
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>Looks like there was an error.</p>;
  }

  return (
    <main className="py-10">
      {/* Page header */}
      <div className="max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl lg:px-8">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-xl font-medium text-gray-900">Properties</h1>
            <p className="text-sm font-medium text-gray-500">
              Manage your hunting properties.
            </p>
          </div>
        </div>
        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <Link
            to="/properties/add"
            className="inline-flex items-center rounded-md border border-transparent bg-green-regular py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
          >
            Add
          </Link>
        </div>
      </div>

      <div className="mt-8 max-w-3xl sm:px-6 lg:max-w-5xl">
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          {data.map((property) => (
            <li
              key={property.id}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border bg-white text-center shadow"
            >
              <div className="flex flex-1 flex-col pb-8">
                <h3 className="mt-6 text-lg font-medium text-green-regular">
                  {property.name}
                </h3>
                <dl className="mt-1 flex grow flex-col justify-between">
                  <dt className="sr-only">GPS Coords</dt>
                  <dd className="text-sm text-gray-500">{`${property.lat} ${property.lng}`}</dd>
                </dl>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1"></div>
                  <div className="-ml-px flex w-0 flex-1">
                    <Link
                      to={`/properties/${property.id}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                    >
                      <PencilIcon
                        className="h-5 w-5 text-green-regular"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Edit</span>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
};
