import * as z from 'zod';
import { JournalModel } from '../journal.models';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ApiFormErrors } from '../../../components/Forms/ApiFormErrors';
import { useEffect, useState } from 'react';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { DatePickerWithIcon, ErrorSummary } from '../../../components';
import { useDeleteJournal, useUpdateJournal } from '../api';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import JournalTypes from '../../../components/JournalTypes';

export const schema = z.object({
  title: z.string().min(1, { message: 'Content is required' }),
  typeName: z.string().min(1, { message: 'Type is required' }),
  description: z.string(),
  date: z.date(),
});

export type ValidationSchema = z.infer<typeof schema>;

interface FormProps {
  close: (status: boolean) => void;
  journal: JournalModel;
}

export const CurrentJournalForm = ({ journal, close }: FormProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { mutate, isLoading, isSuccess, setApiError, apiError } =
    useUpdateJournal();

  const mutateDelete = useDeleteJournal();

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: journal.title,
      description: journal.description,
      date: new Date(journal.date),
      typeName: journal.typeName,
    },
  });

  const onSubmit = (data: ValidationSchema) => {
    const newJournal = {
      ...data,
      id: journal.id,
    };

    setApiError(null);

    mutate(newJournal);
  };

  const handleDelete = (id: string) => {
    mutateDelete.mutate(id);
  };

  useEffect(() => {
    if (mutateDelete.isSuccess) {
      close(false);
    }
  }, [mutateDelete.isSuccess])

  return (
    <div className="sm:px6 lg:px8 flex w-full flex-col justify-center pb-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="text-l mt-4 text-center text-black">
          Add Journal Entry
        </h1>
        {isLoading ? (
          <p className="text-center">Adding journal...</p>
        ) : (
          <div className="text-center">
            {isSuccess ? <p>Journal Updated!</p> : null}
          </div>
        )}
      </div>
      <div className="sm:2-full mt-8 w-full sm:mx-auto">
        <div className="mb-4">
          <ErrorSummary errors={errors} />
          {apiError ? <ApiFormErrors apiError={apiError} /> : null}
        </div>
        <div className="relative bg-white">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200">
              <label htmlFor="entry" className="sr-only">
                Entry
              </label>
              <input
                {...register('title')}
                type="text"
                className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-500 focus:ring-0"
                placeholder="Title"
                required
              />
              <label htmlFor="description" className="sr-only">
                Description
              </label>
              <textarea
                {...register('description')}
                rows={2}
                id="description"
                className="block w-full resize-none border-0 py-0 placeholder:text-gray-500 focus:ring-0 sm:text-sm"
                placeholder="Write some details..."
              />

              {/* Spacer element to match the height of the toolbar */}
              <div aria-hidden="true">
                <div className="py-2">
                  <div className="h-9" />
                </div>
                <div className="h-px" />
                <div className="py-2">
                  <div className="py-px">
                    <div className="h-9" />
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute inset-x-px bottom-0">
              <div className="flex flex-nowrap justify-end space-x-2 p-2 sm:px-3">
                <Controller
                  name="typeName"
                  control={control}
                  render={({ field }) => (<JournalTypes value={field.value} onChange={field.onChange} />)}
                />
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <div className="shrink-0">
                      <DatePicker
                        popperPlacement="left"
                        onChange={field.onChange}
                        dateFormat="MMM, d"
                        ref={(
                          elem: ReactDatePicker & { input: HTMLLabelElement }
                        ) => {
                          elem && field.ref(elem.input);
                        }}
                        selected={field.value}
                        customInput={
                          <DatePickerWithIcon onClick={field.onChange} />
                        }
                      />
                    </div>
                  )}
                />
              </div>
              <div className="flex items-center justify-between space-x-3 border-t border-gray-200 p-2 sm:px-3">
                <div className="flex">
                  <button
                    type="button"
                    className="bg group inline-flex items-center rounded-md border border-red-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                    onClick={() => setConfirmDelete(true)}
                  >
                    <span className="sr-only">Delete</span>
                    <XMarkIcon className="h-5 w-5 fill-red-600 text-red-700 group-hover:text-white" />
                  </button>
                  {confirmDelete ? (
                    <button
                      onClick={() => handleDelete(journal.id)}
                      type="button"
                      className="bg group ml-2 inline-flex items-center rounded-md border border-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-regular focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                    >
                      <span className="sr-only">Confirm Deletion</span>
                      <CheckIcon className="h-5 w-5 text-green-regular group-hover:text-white" />
                    </button>
                  ) : null}
                </div>
                <div className="shrink-0">
                  <button
                    type="submit"
                    className="inline-flex items-center rounded-md border border-transparent bg-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
