import { EditProfileForm, Loading } from 'components';
import { useCurrentUser } from 'hooks';

export const EditProfile = () => {
  const { data, isLoading, isError } = useCurrentUser();

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isError) {
    return <h2>It looks like an error occurred.</h2>;
  }

  const formData = {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    defaultProperty: data.defaultProperty,
  };

  const editProfileForm = {
    formData,
    isLoading,
  };

  return (
    <>
      {/* Main content */}
      <main className="py-10">
        {/* Page header */}
        <div className="max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Edit Profile</h1>
              <p className="text-sm font-medium text-gray-500">Fuller Acres</p>
            </div>
          </div>
        </div>

        <div className="mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1">
            {/* Description list*/}
            <EditProfileForm
              formData={editProfileForm.formData}
              isLoading={editProfileForm.isLoading}
            />
          </div>
        </div>
      </main>
    </>
  );
};
