import { useMutation } from '@tanstack/react-query';
import { Client } from '../../client';

type formTypes = {
  species: number;
};

const addHunt = async (formData: formTypes) => {
  const result = await Client().post('/hunts', formData);
  console.log('result', result);
};

export const useHunts = () => {
  const { mutate: addNewHunt, isLoading: isMutating } = useMutation(addHunt);

  return {
    addNewHunt,
    isMutating,
  };
};

