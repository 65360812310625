import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks';
import { PropertyFeatureModel } from '../AddFeature/feature.models';

export const useGetFeatureById = (featureId: string) => {
  const axios = useApi();

  return useQuery({
    queryKey: ['feature', featureId],
    queryFn: async () => {
      const results = await axios.get<PropertyFeatureModel>(
        `feature/${featureId}`
      );

      return results.data;
    },
  });
};
