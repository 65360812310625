import { Link, NavLink } from 'react-router-dom';
import { ClockIcon, HomeIcon } from '@heroicons/react/24/outline';

import logoIcon from '../../../assets/logo-icon2.jpg';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  { name: 'Journal', href: '/journal', icon: ClockIcon, current: false },
  { name: 'Hunts', href: '/hunts', icon: ClockIcon, current: false },
  { name: 'Map', href: '/map', icon: ClockIcon, current: false },
  { name: 'Features', href: '/features', icon: ClockIcon, current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Props {
  current: string;
}

export const LeftSidebar = ({ current }: Props) => (
  <div className="hidden w-28 overflow-y-auto bg-green-regular md:block">
    <div className="flex w-full flex-col items-center py-6">
      <div className="flex shrink-0 items-center">
        <Link to="/dashboard">
          <img src={logoIcon} alt="huntscribe" className="h-14" />
        </Link>
      </div>
      <div className="mt-6 w-full flex-1 space-y-1 px-2">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) => {
              return classNames(
                isActive
                  ? 'bg-green-accent text-white'
                  : 'text-white hover:bg-green-accent hover:text-white',
                'group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium'
              );
            }}
          >
            <item.icon className={classNames('h-6 w-6')} aria-hidden="true" />
            <span className="mt-2">{item.name}</span>
          </NavLink>
        ))}
      </div>
    </div>
  </div>
);
