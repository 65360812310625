import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.DEV
    ? import.meta.env.VITE_API_URL
    : 'https://api.huntscribe.app/api/v1',
  withCredentials: true,
});

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

const apiClient = axiosInstance;

export { apiClient };
