import React from 'react';

type Props = {
  message: string;
};

export const FormInputError = ({ message }: Props) => (
  <p className="mt-2 text-sm text-red-600" role="alert">
    {message}
  </p>
);
