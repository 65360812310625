import { Menu } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { BarsArrowUpIcon } from '@heroicons/react/20/solid';
import { useInfiniteJournals } from './api';
import { Loading } from '../../components';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { JournalList } from './ui/journalList';
import { Modal } from '../../components/Modal';
import { AddJournalForm } from './ui/addJournalForm';
import { Logo } from '../../components/Logo';
import { useAppState, useAuth } from '../../context';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const Timeline = () => {
  const { state } = useAppState();
  const { state: authState } = useAuth();
  const [openAdd, setOpenAdd] = useState(false);
  const { ref, inView } = useInView();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteJournals(
      authState.user?.id || '',
      state.selectedProperty?.id || ''
    );

  const handleAddClick = () => {
    setOpenAdd(true);
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <>
      {/* Main content */}
      <main className="py-10">
        {/* Page header */}
        <div className="max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Journal</h1>
              <p className="text-sm font-medium text-gray-500">Fuller Acres</p>
            </div>
          </div>
        </div>

        <div className="mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-5xl lg:grid-cols-3">
          <div className="col-span-3 bg-white lg:col-start-1">
            <div className="flex justify-end border-y border-gray-200 py-4 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6">
              <div className="flex justify-end">
                <Menu as="div" className="relative">
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <BarsArrowUpIcon
                      className="-ml-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Menu.Items className="ring-opacity/5 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Name
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Date modified
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Date created
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
                <button
                  className="ml-4 inline-flex items-center rounded-md border border-transparent bg-green-regular py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                  onClick={handleAddClick}
                  type="button"
                >
                  Add
                </button>
              </div>
            </div>
            <>
              {status === 'loading' ? (
                <Loading />
              ) : status === 'error' && !data ? (
                <p>There was an error.</p>
              ) : (
                <>
                  {data?.pages.length > 0 && data?.pages[0].data.length > 0 ? (
                    data.pages.map((page, i) => {
                      const { data = [] } = page;
                      return <JournalList key={i} journals={data} />;
                    })
                  ) : (
                    <div className="mt-6 flex flex-col items-center">
                      <Logo />
                      <h3 className="mt-2 text-sm font-semibold text-gray-900">
                        No journal entries
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Get started by creating a new journal entry.
                      </p>
                      <div className="mt-6">
                        <button
                          className="inline-flex items-center rounded-md border border-transparent bg-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-light focus:ring-offset-2"
                          onClick={() => setOpenAdd(true)}
                          type="button"
                        >
                          <PlusIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          New Journal
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
            {openAdd ? (
              <Modal open={openAdd} setOpen={setOpenAdd}>
                <AddJournalForm modalOpen={setOpenAdd} />
              </Modal>
            ) : null}
          </div>
          {status === 'success' && data?.pages[0].data.length > 0 ? (
            <div className="col-span-3 mx-4 mt-6 flex flex-col items-center justify-center">
              <button
                type="button"
                className="inline-flex w-60 items-center justify-center rounded-md border border-transparent bg-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                disabled={!hasNextPage || isFetchingNextPage}
                onClick={() => fetchNextPage()}
                ref={ref}
              >
                {isFetchingNextPage
                  ? 'Loading...'
                  : hasNextPage
                  ? 'Load more'
                  : 'Nothing more to load'}
              </button>
            </div>
          ) : null}
        </div>
      </main>
    </>
  );
};
