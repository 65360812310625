import axios from "axios";
import { useState } from "react";

interface IWind {
  speed: number;
  gusts: number;
  direction: number;
}

interface IWeather {
  pressure: number;
  temp: number;
  sunrise: string;
  sunset: string;
  wind: IWind;
}

export const useWeather = () => {
  const [weather, setWeather] = useState<IWeather>();

  const getWeather = (lat: string | number, lng: string | number) => {
    const API_URL = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${
      import.meta.env.VITE_WEATHER_KEY
    }&units=imperial`;

    return axios.get(API_URL);
  };

  return {
    getWeather,
    setWeather,
    weather,
  };
};
