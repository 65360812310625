import { AxiosError } from 'axios';

type ErrorSummaryProps = {
  apiError: AxiosError<Error>;
};

export const ApiFormErrors = ({ apiError }: ErrorSummaryProps) => {
  if (!apiError?.response) {
    return null;
  }

  const {
    data: { message = [] },
  } = apiError.response;

  if (!message && !Array.isArray(message) && message.length < 1) {
    return null;
  }

  return (
    <div className="error-summary border border-solid border-red-700 bg-red-100 px-4 py-2">
      <ul className="list-disc pl-4 text-red-700">
        {Array.isArray(message) ? (
          message.map((item, i) => {
            return (
              <li key={i}>
                <p className="text-sm" role="error">
                  {item}
                </p>
              </li>
            );
          })
        ) : (
          <li>
            <p className="text-sm" role="error">
              {message}
            </p>
          </li>
        )}
      </ul>
    </div>
  );
};
