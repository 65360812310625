import React, { forwardRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Location, TimeOfDay } from '../../components';
import { useHunts } from '../../hooks';
import classNames from 'classnames';
import { Weather } from '../../components';
import { InputCounter } from '../../components/InputCounter';

interface ICoords {
  lat: number | string;
  lng: number | string;
}

export type FormValues = {
  bucks: number | string;
  coords: ICoords;
  date: Date;
  doe: number | string;
  notes: string;
  shooter: string;
  timeOfDay: string;
};

type DatePickerWithIconProps = {
  onClick: (e: React.MouseEvent) => void;
  value?: string;
  placeholder?: string;
};

const DatePickerWithIcon = forwardRef<
  HTMLLabelElement,
  DatePickerWithIconProps
>((props, ref) => {
  return (
    <div className="flex items-center justify-between">
      <label
        className="mr-6 flex items-baseline text-sm font-semibold text-green-regular"
        onClick={props.onClick}
        ref={ref}
      >
        {props.value || props.placeholder}
      </label>
      <button onClick={props.onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 fill-green-regular"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </button>
    </div>
  );
});
DatePickerWithIcon.displayName = 'DatePickerWithIcon';

export const AddHunt = () => {
  const { control, register, handleSubmit, setValue, watch } =
    useForm<FormValues>({
      defaultValues: {
        date: new Date(),
        coords: {
          lat: '',
          lng: '',
        },
        notes: '',
        bucks: 0,
        doe: 0,
        timeOfDay: '',
        shooter: '',
      },
    });
  const { isMutating } = useHunts();

  // Watch form values.
  const [watchShooter, watchTimeOfDay, watchCoords] = watch([
    'shooter',
    'timeOfDay',
    'coords',
  ]);

  const handleLocationChange = useCallback(
    (value) => {
      setValue('coords', value);
    },
    [setValue]
  );

  const onSubmit = (data: FormValues) => {
    console.log('submitting', data);
    // const result = addNewHunt({ species: 1 })
  };

  if (isMutating) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className="flex flex-1 items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        <div className="mx-auto max-w-7xl px-4 pb-14 pt-8 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <section>
              <div className="mt-6">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Location
                      {...field}
                      lat={field.value.lat}
                      lng={field.value.lng}
                      handleOnChange={handleLocationChange}
                    />
                  )}
                  name="coords"
                />
                <div>
                  <h2 className="text-center text-4xl text-gray-300">Time</h2>
                </div>
                <div className="details">
                  <div className="time">
                    <div className="mt-5 grid grid-cols-1 divide-y divide-gray-200 rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                      <div className="flex px-4 py-5 sm:p-6">
                        <div className="flex-1 px-4">
                          <div className="mb-6 text-base font-normal text-gray-500">
                            Date
                          </div>
                          <div className="mt-1 flex justify-between">
                            <Controller
                              control={control}
                              render={({ field }) => (
                                <DatePicker
                                  onChange={field.onChange}
                                  dateFormat="E, MMM d"
                                  selected={field.value}
                                  customInput={
                                    <DatePickerWithIcon
                                      onClick={field.onChange}
                                    />
                                  }
                                />
                              )}
                              name="date"
                            />
                          </div>
                        </div>
                      </div>
                      <TimeOfDay register={register} value={watchTimeOfDay} />
                    </div>
                  </div>
                  <div>
                    <h2 className="mt-5 text-center text-4xl text-gray-300">
                      Weather
                    </h2>
                  </div>
                  <div className="weather">
                    <div className="mt-5 grid grid-cols-1 divide-y divide-gray-200 rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                      <Weather lat={watchCoords.lat} lng={watchCoords.lng} />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="mt-5 text-center text-4xl text-gray-300">
                    Hunt
                  </h2>
                </div>
                <div className="hunt">
                  <div className="hunt-details">
                    <div className="mt-5 grid grid-cols-1 divide-y divide-gray-200 rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                      <div className="flex px-4 py-5 sm:p-6">
                        <div className="w-full px-4">
                          <div className="mb-6 text-base font-normal text-gray-900">
                            Shooter
                          </div>
                          <div className="mt-1 flex items-center justify-between md:block lg:flex">
                            <div>
                              <input
                                {...register('shooter')}
                                id="shooterYes"
                                value="yes"
                                className="hidden"
                                type="radio"
                              />
                              <label
                                htmlFor="shooterYes"
                                className={classNames({
                                  'text-white bg-green-regular':
                                    watchShooter === 'yes',
                                  'text-green-regular bg-white':
                                    watchShooter === 'no' ||
                                    watchShooter === '',
                                  'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm hover:text-white hover:bg-green-regular focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-regular':
                                    true,
                                })}
                              >
                                Yes
                              </label>
                            </div>
                            <div>
                              <input
                                {...register('shooter')}
                                id="shooterNo"
                                value="no"
                                className="hidden"
                                type="radio"
                              />
                              <label
                                htmlFor="shooterNo"
                                className={classNames({
                                  'text-white bg-green-regular':
                                    watchShooter === 'no',
                                  'text-green-regular bg-white':
                                    watchShooter === 'yes' ||
                                    watchShooter === '',
                                  'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm hover:text-white hover:bg-green-regular focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-regular':
                                    true,
                                })}
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex px-4 py-5 sm:p-6">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <InputCounter
                              {...field}
                              labelText="Bucks"
                              onChange={(value) => setValue('bucks', value)}
                            />
                          )}
                          name="bucks"
                        />
                      </div>
                      <div className="flex px-4 py-5 sm:p-6">
                        <Controller
                          control={control}
                          render={({ field }) => {
                            return (
                              <InputCounter
                                {...field}
                                labelText="Doe"
                                onChange={(value) => setValue('doe', value)}
                              />
                            );
                          }}
                          name="bucks"
                        />
                      </div>
                      <div className="flex px-4 py-5 sm:p-6">
                        <div className="w-full px-4">
                          <div className="text-base font-normal text-gray-900">
                            Notes
                          </div>
                          <div className="mt-1 flex items-baseline md:block lg:flex">
                            <textarea
                              id="notes"
                              rows={3}
                              className="block w-full max-w-lg rounded-md border border-gray-300 text-gray-500 shadow-sm focus:border-green-regular focus:ring-green-regular sm:text-sm"
                              {...register('notes')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-8">
                  <button
                    type="submit"
                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-regular px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                  >
                    Save Hunt
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </main>
    </div>
  );
};
