import React from 'react';
import { cn } from '../../utils';

interface TabPanelProps {
  children: React.ReactNode;
  classNames?: string;
}

export const TabPanel = ({ children, classNames }: TabPanelProps) => (
  <div className={cn({ [`${classNames}`]: classNames })}>{children}</div>
);
