import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type ErrorSummaryProps = {
  errors: FieldErrors;
};

export const ErrorSummary = ({ errors }: ErrorSummaryProps) => {
  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <div className="error-summary border border-solid border-red-700 bg-red-100 px-4 py-2">
      <ul className="list-disc pl-4 text-red-700">
        {Object.keys(errors).map((fieldName) => {
          return (
            <ErrorMessage
              errors={errors}
              name={fieldName}
              key={fieldName}
              render={({ message }) => {
                if (message && typeof message === 'string') {
                  return (
                    <li>
                      <p className="text-sm" role="error">
                        {message}
                      </p>
                    </li>
                  );
                }

                if (Array.isArray(message)) {
                  return message.map((item, i) => {
                    return (
                      <li key={i}>
                        <p className="text-sm" role="error">
                          {item}
                        </p>
                      </li>
                    );
                  })
                }
              }}
            />
          );
        })}
      </ul>
    </div>
  );
};
