import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks/useApi';
import { QUERY_KEYS } from '../../constants';

export interface CurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  loggedIn: boolean;
  defaultProperty: string;
}

export const useCurrentUser = () => {
  const axios = useApi();

  const getCurrentUser = async () => {
    const result = await axios.get<CurrentUser>('/auth/me');
    return {
      id: result.data.id,
      firstName: result.data.firstName,
      lastName: result.data.lastName,
      email: result.data.email,
      loggedIn: true,
      defaultProperty: result.data.defaultProperty,
    };
  };
  return useQuery<CurrentUser>([QUERY_KEYS.USER], getCurrentUser);
};
