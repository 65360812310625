import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  ClockIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { BuckIcon, DoeIcon, TargetIcon } from 'components';
import { Link } from 'react-router-dom';

const positions = [
  {
    id: 1,
    type: 'Ok',
    standLocation: 'Pines 1',
    buck: '1',
    doe: '0',
    huntDate: '2020-01-07',
    huntDateFull: 'January 7, 2020',
    shooter: '1',
  },
  {
    id: 2,
    type: 'Poor',
    standLocation: 'Ridge 1',
    buck: '1',
    doe: '4',
    huntDate: '2020-01-07',
    huntDateFull: 'January 7, 2020',
    shooter: '0',
  },
  {
    id: 3,
    type: 'Great',
    standLocation: 'Pines 3',
    buck: '2',
    doe: '2',
    huntDate: '2020-01-14',
    huntDateFull: 'January 14, 2020',
    shooter: '1',
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const Hunts = () => (
  <>
    {/* Main content */}
    <main className="py-10">
      {/* Page header */}
      <div className="max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl lg:px-8">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Hunts</h1>
            <p className="text-sm font-medium text-gray-500">Fuller Acres</p>
          </div>
        </div>
      </div>

      <div className="mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-2">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="border-b border-gray-200 py-5 px-4 sm:px-6">
                <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                  <Link
                    to="add"
                    className="inline-flex items-center rounded-md border border-transparent bg-green-regular py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                  >
                    Add
                  </Link>
                  <Menu as="div" className="relative inline-block text-left">
                    <div className="flex h-full items-center">
                      <Menu.Button className="inline-flex w-full justify-center border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2 focus:ring-offset-gray-100">
                        2022
                        <ChevronDownIcon
                          className="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                2021
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                2020
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="px-4sm:px-6 overflow-hidden bg-white py-5 sm:rounded-md">
                <div className="flow-root">
                  <ul role="list" className="divide-y divide-gray-200">
                    {positions.map((position) => (
                      <li key={position.id}>
                        <a href="#" className="block hover:bg-gray-50">
                          <div className="p-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <p className="truncate text-sm font-medium text-green-regular">
                                <time dateTime={position.huntDate}>
                                  {position.huntDateFull}
                                </time>
                              </p>
                              <div className="ml-2 flex shrink-0">
                                <div className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  {position.type}
                                </div>
                              </div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                              <div className="sm:flex">
                                <div className="flex items-center text-sm text-gray-500">
                                  <MapPinIcon
                                    className="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {position.standLocation}
                                </div>
                              </div>
                              <div className="mt-2 ml-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-0">
                                <div className="mt-2 ml-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                  <BuckIcon />
                                  {position.buck}
                                </div>
                                <div className="mt-2 ml-6 flex items-center text-sm text-gray-500 sm:mt-0">
                                  <DoeIcon />
                                  {position.doe}
                                </div>
                                <div className="mt-2 ml-6 flex items-center text-sm text-gray-500 sm:mt-0">
                                  <TargetIcon />
                                  {position.shooter}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </>
);
