import { AddPropertyForm } from '../AddPropertyForm';

export const AddProperty = () => (
  <main className="py-10 px-8">
    <div className="max-w-3xl border-b pb-4 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl">
      <div className="flex items-center">
        <h1 className="text-2xl font-medium text-gray-900">Add Property</h1>
      </div>
    </div>
    <div className="mt-8 max-w-3xl lg:max-w-5xl">
      <AddPropertyForm />
    </div>
  </main>
);
