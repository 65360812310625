import classNames from 'classnames';

import logoIcon from '../../assets/logo-gray.svg';

interface LogoProps {
  size?: string;
}

export const Logo = ({ size = 'medium' }: LogoProps) => {
  const css = classNames({
    'h-[100px]': size === 'small',
    'h-[200px]': size === 'medium',
    'h-[300px]': size === 'large',
  });

  return <img src={logoIcon} alt="huntscribe" className={css} />;
};
