import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { useGetFeatureById } from '../features.api';
import {
  BuckIcon,
  DoeIcon,
  Loading,
  TabItem,
  TabItems,
  TabPanel,
  TabPanels,
  Tabs,
  TargetIcon,
} from '../../../components';
import { FeatureMap } from '../../../components/Map/FeatureMap';
import { Listbox, Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { ChevronDownIcon, MapPinIcon } from '@heroicons/react/24/outline';

const activity = [
  {
    id: 1,
    type: 'created',
    person: { name: 'Chelsea Hagon' },
    date: '7d ago',
    dateTime: '2023-01-23T10:32',
  },
  {
    id: 2,
    type: 'edited',
    person: { name: 'Chelsea Hagon' },
    date: '6d ago',
    dateTime: '2023-01-23T11:03',
  },
  {
    id: 3,
    type: 'sent',
    person: { name: 'Chelsea Hagon' },
    date: '6d ago',
    dateTime: '2023-01-23T11:24',
  },
  {
    id: 4,
    type: 'commented',
    person: {
      name: 'Chelsea Hagon',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    comment:
      'Called client, they reassured me the invoice would be paid by the 25th.',
    date: '3d ago',
    dateTime: '2023-01-23T15:56',
  },
  {
    id: 5,
    type: 'viewed',
    person: { name: 'Alex Curren' },
    date: '2d ago',
    dateTime: '2023-01-24T09:12',
  },
  {
    id: 6,
    type: 'paid',
    person: { name: 'Alex Curren' },
    date: '1d ago',
    dateTime: '2023-01-24T09:20',
  },
];
const moods = [
  {
    name: 'Excited',
    value: 'excited',
    icon: FireIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Loved',
    value: 'loved',
    icon: HeartIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-400',
  },
  {
    name: 'Happy',
    value: 'happy',
    icon: FaceSmileIcon,
    iconColor: 'text-white',
    bgColor: 'bg-green-400',
  },
  {
    name: 'Sad',
    value: 'sad',
    icon: FaceFrownIcon,
    iconColor: 'text-white',
    bgColor: 'bg-yellow-400',
  },
  {
    name: 'Thumbsy',
    value: 'thumbsy',
    icon: HandThumbUpIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
  {
    name: 'I feel nothing',
    value: null,
    icon: XMarkIconMini,
    iconColor: 'text-gray-400',
    bgColor: 'bg-transparent',
  },
];
const stats = [
  { name: 'Total Hunts', stat: '23' },
  { name: 'Bucks', stat: '20' },
  { name: 'Targets', stat: '3' },
  { name: 'Sightings', stat: '34' },
];
const positions = [
  {
    id: 1,
    type: 'Ok',
    standLocation: 'Pines 1',
    buck: '1',
    doe: '0',
    huntDate: '2020-01-07',
    huntDateFull: 'January 7, 2020',
    shooter: '1',
  },
  {
    id: 2,
    type: 'Poor',
    standLocation: 'Ridge 1',
    buck: '1',
    doe: '4',
    huntDate: '2020-01-07',
    huntDateFull: 'January 7, 2020',
    shooter: '0',
  },
  {
    id: 3,
    type: 'Great',
    standLocation: 'Pines 3',
    buck: '2',
    doe: '2',
    huntDate: '2020-01-14',
    huntDateFull: 'January 14, 2020',
    shooter: '1',
  },
];

export const FeatureDetailsPage = () => {
  const { id = '' } = useParams();
  const { data: feature, isLoading } = useGetFeatureById(id);
  const [selected, setSelected] = useState(moods[5]);

  if (!feature || isLoading) {
    return <Loading />;
  }

  return (
    <div className="px-4 sm:px-6">
      <div className="divide-y divide-gray-200 overflow-hidden bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h1>Navigation will go here</h1>
        </div>
      </div>
      <div className="mx-auto py-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {/* Invoice summary */}
          <div className="lg:col-start-3 lg:row-end-1">
            <h2 className="sr-only">Summary</h2>
            <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
              <dl className="flex flex-wrap">
                <div className="flex-auto pl-6 pt-6">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Name
                  </dt>
                  <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                    {feature.name}
                  </dd>
                </div>
                <div className="flex-none self-end px-6 pt-4">
                  <dt className="sr-only">Status</dt>
                  <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                    Active
                  </dd>
                </div>
                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex-none text-sm font-medium leading-6 text-gray-900">
                    Type:
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    Treestand
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <dt className="flex-none text-sm font-medium leading-6 text-gray-900">
                      Created:
                    </dt>
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    <time dateTime="2023-01-31">January 31, 2023</time>
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <dt className="flex-none text-sm font-medium leading-6 text-gray-900">
                      Lat/Lng:
                    </dt>
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    39.2345, -79.2345
                  </dd>
                </div>
              </dl>
              <div className="mt-6 border-t border-gray-900/5 p-6">
                Some kind of footer here?
              </div>
            </div>
          </div>

          {/* Invoice */}
          <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:pb-20">
            <div className="mb-8">
              <FeatureMap
                customStyles={{ height: '400px' }}
                features={[feature]}
                initialLocation={{
                  lat: feature.lat,
                  lng: feature.lng,
                }}
                mapId="featureMap"
              />
            </div>
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Quick Stats
            </h2>
            <div>
              <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                {stats.map((item) => (
                  <div
                    key={item.name}
                    className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-4"
                  >
                    <dt className="truncate text-xs font-medium text-gray-500">
                      {item.name}
                    </dt>
                    <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
                      {item.stat}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
            <Tabs>
              <TabItems>
                <TabItem>Hunts</TabItem>
                <TabItem>Tasks</TabItem>
                <TabItem>Journal</TabItem>
                <TabItem>Sightings</TabItem>
              </TabItems>
              <TabPanels>
                <TabPanel>
                  <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="border-b border-gray-200 py-5 px-4 sm:px-6">
                          <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                            <Link
                              to="add"
                              className="inline-flex items-center rounded-md border border-transparent bg-green-regular py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                            >
                              Add
                            </Link>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div className="flex h-full items-center">
                                <Menu.Button className="inline-flex w-full justify-center border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2 focus:ring-offset-gray-100">
                                  2022
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                        >
                                          2021
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                        >
                                          2020
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                        <div className="px-4sm:px-6 overflow-hidden bg-white py-5 sm:rounded-md">
                          <div className="flow-root">
                            <ul
                              role="list"
                              className="divide-y divide-gray-200"
                            >
                              {positions.map((position) => (
                                <li key={position.id}>
                                  <a
                                    href="#"
                                    className="block hover:bg-gray-50"
                                  >
                                    <div className="p-4 sm:px-6">
                                      <div className="flex items-center justify-between">
                                        <p className="truncate text-sm font-medium text-green-regular">
                                          <time dateTime={position.huntDate}>
                                            {position.huntDateFull}
                                          </time>
                                        </p>
                                        <div className="ml-2 flex shrink-0">
                                          <div className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                            {position.type}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-2 sm:flex sm:justify-between">
                                        <div className="sm:flex">
                                          <div className="flex items-center text-sm text-gray-500">
                                            <MapPinIcon
                                              className="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {position.standLocation}
                                          </div>
                                        </div>
                                        <div className="mt-2 ml-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-0">
                                          <div className="mt-2 ml-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                            <BuckIcon />
                                            {position.buck}
                                          </div>
                                          <div className="mt-2 ml-6 flex items-center text-sm text-gray-500 sm:mt-0">
                                            <DoeIcon />
                                            {position.doe}
                                          </div>
                                          <div className="mt-2 ml-6 flex items-center text-sm text-gray-500 sm:mt-0">
                                            <TargetIcon />
                                            {position.shooter}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="col-span-3 bg-white lg:col-start-1">
                    <div className="flex justify-end border-y border-gray-200 py-4 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6">
                      <div className="flex justify-end">
                        <div className="relative" data-headlessui-state="">
                          <button
                            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            id="headlessui-menu-button-31"
                            type="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-headlessui-state=""
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                              className="-ml-0.5 h-5 w-5 text-gray-400"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            Sort
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="-mr-1 h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <button
                          className="ml-4 inline-flex items-center rounded-md border border-transparent bg-green-regular py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="mt-0 divide-y divide-gray-200 border-x border-b border-gray-200"
                    >
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">Fuller Journal Entryy</h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Apr</div>
                            <div className="mt-0">15</div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">Planted food plot</h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Apr</div>
                            <div className="mt-0">22</div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">Journal Entry</h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Jun</div>
                            <div className="mt-0">05</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul
                      role="list"
                      className="mt-0 divide-y divide-gray-200 border-x border-b border-gray-200"
                    >
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">
                                And another journal entry
                              </h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Jun</div>
                            <div className="mt-0">05</div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">
                                Here in the smokey mountains
                              </h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Jun</div>
                            <div className="mt-0">05</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="col-span-3 bg-white lg:col-start-1">
                    <div className="flex justify-end border-y border-gray-200 py-4 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6">
                      <div className="flex justify-end">
                        <div className="relative" data-headlessui-state="">
                          <button
                            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            id="headlessui-menu-button-31"
                            type="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-headlessui-state=""
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                              className="-ml-0.5 h-5 w-5 text-gray-400"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            Sort
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="-mr-1 h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <button
                          className="ml-4 inline-flex items-center rounded-md border border-transparent bg-green-regular py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="mt-0 divide-y divide-gray-200 border-x border-b border-gray-200"
                    >
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">Fuller Journal Entryy</h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Apr</div>
                            <div className="mt-0">15</div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">Planted food plot</h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Apr</div>
                            <div className="mt-0">22</div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">Journal Entry</h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Jun</div>
                            <div className="mt-0">05</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul
                      role="list"
                      className="mt-0 divide-y divide-gray-200 border-x border-b border-gray-200"
                    >
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">
                                And another journal entry
                              </h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Jun</div>
                            <div className="mt-0">05</div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                        role="button"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              <h2 className="text-sm">
                                Here in the smokey mountains
                              </h2>
                            </div>
                            <p className="flex space-x-2 text-sm text-gray-500">
                              <span>Task</span>
                            </p>
                          </div>
                          <div className="shrink-0 flex-col items-center border-l pl-4 sm:flex">
                            <div className="uppercase">Jun</div>
                            <div className="mt-0">05</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>Sightings</TabPanel>
              </TabPanels>
            </Tabs>
          </div>

          <div className="lg:col-start-3">
            {/* Activity feed */}
            <h2 className="text-sm font-semibold leading-6 text-gray-900">
              Activity
            </h2>
            <ul role="list" className="mt-6 space-y-6">
              {activity.map((activityItem, activityItemIdx) => (
                <li key={activityItem.id} className="relative flex gap-x-4">
                  <div
                    className={classNames(
                      activityItemIdx === activity.length - 1
                        ? 'h-6'
                        : '-bottom-6',
                      'absolute left-0 top-0 flex w-6 justify-center'
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  {activityItem.type === 'commented' ? (
                    <>
                      <img
                        src={activityItem.person.imageUrl}
                        alt=""
                        className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                      />
                      <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                        <div className="flex justify-between gap-x-4">
                          <div className="py-0.5 text-xs leading-5 text-gray-500">
                            <span className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </span>{' '}
                            commented
                          </div>
                          <time
                            dateTime={activityItem.dateTime}
                            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                          >
                            {activityItem.date}
                          </time>
                        </div>
                        <p className="text-sm leading-6 text-gray-500">
                          {activityItem.comment}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        {activityItem.type === 'paid' ? (
                          <CheckCircleIcon
                            className="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                        )}
                      </div>
                      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                        <span className="font-medium text-gray-900">
                          {activityItem.person.name}
                        </span>{' '}
                        {activityItem.type} the invoice.
                      </p>
                      <time
                        dateTime={activityItem.dateTime}
                        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                      >
                        {activityItem.date}
                      </time>
                    </>
                  )}
                </li>
              ))}
            </ul>

            {/* New comment form */}
            <div className="mt-6 flex gap-x-3">
              <img
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
              />
              <form action="#" className="relative flex-auto">
                <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                  <label htmlFor="comment" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    rows={2}
                    name="comment"
                    id="comment"
                    className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Add your comment..."
                    defaultValue={''}
                  />
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Attach a file</span>
                      </button>
                    </div>
                    <div className="flex items-center">
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="sr-only">
                              Your mood
                            </Listbox.Label>
                            <div className="relative">
                              <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                <span className="flex items-center justify-center">
                                  {selected.value === null ? (
                                    <span>
                                      <FaceSmileIcon
                                        className="h-5 w-5 shrink-0"
                                        aria-hidden="true"
                                      />
                                      <span className="sr-only">
                                        Add your mood
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span
                                        className={classNames(
                                          selected.bgColor,
                                          'flex h-8 w-8 items-center justify-center rounded-full'
                                        )}
                                      >
                                        <selected.icon
                                          className="h-5 w-5 shrink-0 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="sr-only">
                                        {selected.name}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="ring-opacity/5 absolute z-10 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                  {moods.map((mood) => (
                                    <Listbox.Option
                                      key={mood.value}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-gray-100' : 'bg-white',
                                          'relative cursor-default select-none px-3 py-2'
                                        )
                                      }
                                      value={mood}
                                    >
                                      <div className="flex items-center">
                                        <div
                                          className={classNames(
                                            mood.bgColor,
                                            'flex h-8 w-8 items-center justify-center rounded-full'
                                          )}
                                        >
                                          <mood.icon
                                            className={classNames(
                                              mood.iconColor,
                                              'h-5 w-5 flex-shrink-0'
                                            )}
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <span className="ml-3 block truncate font-medium">
                                          {mood.name}
                                        </span>
                                      </div>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Comment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
