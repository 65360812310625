import { useContext } from 'react';
import { createCtx } from 'services/createContext';

const initialState = {
  step: 1,
  success: false,
};

interface AddFeatureContext {
  step: number;
  success: boolean;
}

type Action =
  | { type: 'STEP_FORWARD'; payload: number }
  | { type: 'STEP_BACK' }
  | { type: 'SUCCESS'; payload: boolean }
  | { type: 'RESET' };

function reducer(state: AddFeatureContext, action: Action): AddFeatureContext {
  switch (action.type) {
    case 'STEP_FORWARD':
      return {
        ...state,
        step: action.payload,
      };
    case 'STEP_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'SUCCESS':
      return {
        ...state,
        success: action.payload,
      };
    case 'RESET':
      return {
        ...initialState,
      };
    default:
      throw new Error();
  }
}

const [ctx, AddFeatureProvider] = createCtx(reducer, initialState);

const useAddFeatureContext = () => {
  return useContext(ctx);
};

export { useAddFeatureContext, AddFeatureProvider };
