import { PlusIcon } from '@heroicons/react/24/outline';
import { Loading } from 'components';
import { Logo } from 'components/Logo';
import { useGetOwnerProperties } from 'pages/Properties/propertyApi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context';

export const Dashboard = () => {
  const { state } = useAuth();
  const { data, isError, isLoading } = useGetOwnerProperties(
    state.user?.id || ''
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>It looks like there was a problem.</p>;
  }

  if (data.length < 1) {
    return (
      <div className="flex flex-col items-center">
        <Logo />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          No properties
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a new property.
        </p>
        <div className="mt-6">
          <Link
            to="/properties/add"
            className="inline-flex items-center rounded-md border border-transparent bg-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-light focus:ring-offset-2"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Property
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center pt-24">
      <div className="flex items-center justify-center">
        <Logo size="small" />
      </div>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">Welcome!</h3>
      <p className="mt-1 text-sm text-gray-500">
        Looks like you already have some properties!
      </p>
    </div>
  );
};
