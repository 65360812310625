import { MapProvider } from 'react-map-gl';
import { useAppState } from '../../context';
import { useGetPropertyFeatures } from '../Properties/propertyApi';
import { Loading } from '../../components';
import { FeatureMap } from '../../components/Map/FeatureMap';

export const Map = () => {
  const { state } = useAppState();
  const { data, isLoading } = useGetPropertyFeatures(
    state.selectedProperty?.id || ''
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {/* Main content */}
      <main className="py-10">
        {/* Page header */}
        <div className="max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Maps</h1>
              <p className="text-sm font-medium text-gray-500">Fuller Acres</p>
            </div>
          </div>
        </div>

        <div className="mt-8 grid max-w-3xl grid-cols-1 gap-6 px-4 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <h2>Maps go here!</h2>
              {state.selectedProperty ? (
                <MapProvider>
                  <FeatureMap
                    features={data}
                    mapId="customMap"
                    initialLocation={{
                      lat: state.selectedProperty.lat,
                      lng: state.selectedProperty.lng,
                    }}
                  />
                </MapProvider>
              ) : null}
            </section>
          </div>
        </div>
      </main>
    </>
  );
};
