import React from 'react';

export const DoeIcon = () => {
  return (
    <div className="-mt-3 mr-1.5 h-3 w-3 shrink-0 text-gray-400">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        // width="660.000000pt"
        // height="1280.000000pt"
        viewBox="0 0 660.000000 1280.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g
          transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
          fill="#9ca3af"
          stroke="none"
        >
          <path
            d="M2853 12785 c-86 -37 -101 -183 -53 -508 27 -182 57 -241 158 -306
42 -27 55 -44 100 -135 53 -106 84 -142 131 -152 14 -3 51 -14 81 -25 30 -11
118 -34 195 -50 259 -54 283 -68 354 -195 37 -66 39 -74 34 -132 -5 -57 -3
-67 24 -111 20 -31 37 -79 47 -132 27 -136 102 -418 136 -512 33 -91 90 -171
156 -222 52 -40 135 -75 177 -75 21 0 36 -3 34 -7 -3 -5 59 -280 138 -613 109
-466 139 -609 131 -621 -6 -8 -28 -35 -49 -59 l-37 -44 -145 13 c-244 20 -370
45 -1020 201 -617 148 -1011 165 -1330 59 -129 -43 -530 -257 -743 -396 -158
-104 -279 -208 -353 -304 -35 -46 -82 -99 -104 -118 -128 -114 -193 -201 -242
-323 -23 -55 -26 -82 -31 -218 -10 -317 50 -563 189 -777 29 -45 49 -92 70
-173 16 -60 29 -119 29 -129 0 -11 19 -79 41 -153 94 -303 107 -699 34 -1053
-26 -126 -100 -339 -161 -463 -48 -97 -67 -124 -126 -180 -231 -220 -373 -640
-478 -1412 -10 -80 -24 -169 -29 -198 -35 -178 -61 -572 -61 -912 0 -304 -21
-542 -67 -750 -14 -63 -28 -128 -30 -145 -3 -16 -16 -52 -29 -79 -69 -142 16
-330 155 -344 66 -6 93 2 135 39 28 24 38 43 50 98 9 38 15 106 15 152 0 46 4
129 10 184 6 55 13 120 16 145 9 83 34 292 70 585 19 160 40 333 46 385 33
301 96 569 201 850 54 147 162 378 232 499 25 44 60 129 82 196 58 188 73 217
130 250 130 75 278 200 405 341 44 49 161 197 260 329 98 132 212 283 253 335
88 111 240 273 231 246 -17 -56 -51 -195 -95 -391 -107 -478 -177 -682 -289
-843 -89 -127 -121 -393 -80 -662 26 -170 81 -378 189 -720 93 -295 117 -375
159 -540 65 -253 109 -497 130 -735 8 -89 22 -147 71 -305 136 -432 200 -595
249 -629 100 -72 282 -72 383 0 28 20 30 26 26 70 -7 73 -50 148 -145 250 -47
52 -96 110 -109 129 -14 21 -55 149 -103 325 -44 160 -107 388 -140 508 -46
167 -72 291 -111 530 -29 172 -87 522 -130 780 l-79 467 120 186 c212 328 278
422 437 623 223 280 518 643 716 881 94 112 176 210 181 216 8 10 66 10 284
-1 151 -8 288 -17 306 -21 l31 -6 13 -101 c41 -346 238 -1149 506 -2067 75
-257 83 -295 94 -461 9 -127 15 -163 50 -270 41 -131 87 -340 120 -549 29
-179 47 -387 71 -780 11 -190 29 -419 40 -510 10 -91 22 -219 26 -285 4 -66
11 -131 17 -145 6 -14 13 -113 16 -220 l6 -195 36 -65 c49 -90 106 -125 198
-125 54 0 76 6 118 29 48 26 53 32 60 75 4 25 8 98 8 161 -1 113 -2 117 -47
230 -74 185 -105 423 -178 1370 -37 487 -46 584 -66 731 -15 114 -15 117 8
200 33 124 33 325 -1 424 -13 39 -30 82 -37 97 -31 61 -94 348 -134 606 -30
196 -95 717 -130 1039 l-30 282 34 113 c19 62 49 152 66 199 l31 86 65 -34
c73 -39 173 -70 284 -88 41 -6 74 -16 73 -21 -1 -6 -11 -55 -22 -110 -47 -235
-62 -470 -50 -791 9 -237 15 -308 60 -728 27 -245 29 -289 19 -352 -13 -85 -1
-253 30 -403 11 -52 29 -167 40 -255 11 -88 27 -209 36 -270 59 -429 68 -551
59 -772 -12 -295 -11 -300 35 -563 30 -166 50 -419 50 -622 0 -145 3 -187 19
-238 39 -125 100 -176 211 -173 71 1 206 43 228 71 18 23 15 60 -12 140 -14
39 -50 185 -81 324 -31 139 -67 285 -80 323 -121 356 -177 1519 -96 2015 23
145 29 292 17 441 -6 72 -5 128 3 173 6 36 15 94 21 128 5 35 18 122 30 193
74 474 120 963 120 1280 0 207 0 211 25 247 52 77 135 318 181 528 9 41 26
107 39 145 132 399 106 1092 -61 1660 -76 259 -248 537 -383 623 -68 42 -144
114 -178 166 -14 21 -49 92 -78 158 -30 65 -72 146 -95 179 -46 67 -46 67
-344 1059 -92 305 -186 612 -208 682 -37 117 -40 134 -34 198 8 92 30 147 101
253 87 132 142 177 267 220 57 20 155 46 218 57 249 45 320 86 386 224 19 39
34 76 34 83 0 7 10 18 23 24 80 43 148 122 171 202 22 79 48 290 49 402 0 84
-4 109 -24 159 -35 85 -63 98 -167 80 -291 -52 -426 -85 -503 -125 -53 -27
-105 -92 -151 -189 -120 -255 -150 -309 -202 -366 -82 -91 -89 -93 -183 -54
-43 18 -110 48 -150 67 -70 34 -75 35 -155 30 -46 -4 -109 -9 -140 -13 -50 -6
-65 -3 -124 25 -77 36 -141 40 -264 17 -53 -10 -107 -12 -186 -8 l-111 7 -58
45 c-72 55 -154 150 -265 307 -109 153 -191 240 -255 268 -74 33 -180 60 -370
94 -195 35 -180 34 -222 16z"
          />
        </g>
      </svg>
    </div>
  );
};
