import axios from 'axios';
import { useAuth } from 'context';

export const useRefreshToken = () => {
  const { dispatch } = useAuth();

  const refresh = async () => {
    const url = import.meta.env.DEV
      ? import.meta.env.VITE_API_URL
      : 'https://api.huntscribe.app/api/v1';
    const result = await axios.get(`${url}/auth/refresh`, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    dispatch({ type: 'SET_ACCESS_TOKEN', payload: result.data.accessToken });
    return result;
  };

  return refresh;
};
