import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { CurrentUser, useApi } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ApiFormErrors } from '../ApiFormErrors';
import { AxiosError } from 'axios';
import { FormInputError } from '../FormInputError';
import { useQueryClient } from '@tanstack/react-query';
import { PropertyFormMenu } from '../../PropertyMenu';

type FormData = Omit<CurrentUser, 'loggedIn'>;

interface FormProps {
  formData: FormData;
  isLoading: boolean;
}

const schema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'First name is required' })
    .regex(new RegExp(/^[a-zA-Z\s]/), 'Letter characters only'),
  lastName: z
    .string()
    .min(1, { message: 'Last name is required' })
    .regex(new RegExp(/^[a-zA-Z\s]/), 'Letter characters only'),
  defaultProperty: z.string(),
});

export type ValidationSchema = z.infer<typeof schema>;

export const EditProfileForm = ({ formData, isLoading }: FormProps) => {
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const navigate = useNavigate();
  const axios = useApi();
  const queryClient = useQueryClient();

  const { firstName, lastName, defaultProperty } = formData;

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<ValidationSchema>({
    criteriaMode: 'firstError',
    defaultValues: {
      firstName,
      lastName,
      defaultProperty: defaultProperty || '',
    },
    resolver: zodResolver(schema),
  });

  const {
    isSuccess,
    mutate,
    isLoading: mutateLoading,
  } = useMutation({
    mutationFn: (data: ValidationSchema) => {
      setApiError(null);
      return axios.patch('/user', data);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(['user']);
      queryClient.invalidateQueries(['properties']);
    },
    onError: (error: AxiosError<Error>) => {
      setApiError(error);
    },
  });

  const onSubmit: SubmitHandler<ValidationSchema> = async (data) => {
    mutate(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-8 divide-y divide-gray-200"
    >
      <div className="space-y-8 divide-y divide-gray-200">
        {isSuccess ? (
          <div>
            <div className="mt-4">
              <p>Property Updated.</p>
            </div>
          </div>
        ) : null}
        <div className="pt-8">
          {apiError ? (
            <div>
              <ApiFormErrors apiError={apiError} />
            </div>
          ) : null}
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="firstName"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  {...register('firstName')}
                  autoComplete="firstName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-regular focus:ring-green-regular sm:text-sm"
                  id="firstName"
                  required
                  type="text"
                />
                {errors.firstName?.message && (
                  <FormInputError message={errors.firstName.message} />
                )}
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="lastName"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  {...register('lastName')}
                  autoComplete="lastName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-regular focus:ring-green-regular sm:text-sm"
                  id="lastName"
                  required
                  type="text"
                />
                {errors.lastName?.message && (
                  <FormInputError message={errors.lastName.message} />
                )}
              </div>
            </div>

            <div className="sm:col-span-4">
              <Controller
                control={control}
                render={({ field }) => (
                  <PropertyFormMenu
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                name="defaultProperty"
              />
            </div>

            <div className="sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <p className="ml-3 block w-full text-gray-400 sm:text-sm">
                  {formData.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
            type="button"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            disabled={isLoading}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
          >
            {`${!isLoading && !mutateLoading ? 'Save' : 'Loading...'}`}
          </button>
        </div>
      </div>
    </form>
  );
};
