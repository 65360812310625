import { PropertyFeatureModel } from '../../../AddFeature/feature.models';
import { Link } from 'react-router-dom';

interface FeatureListItemProps {
  feature: PropertyFeatureModel;
  type: string;
}

export const FeatureListItem = ({ feature, type }: FeatureListItemProps) => {
  return (
    <li className="relative hover:bg-gray-50 ">
      <Link to={`/feature/${feature.id}`}>
        <div className="flex items-center justify-between space-x-4 py-5 pl-4 pr-6 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6">
          {/* Repo name and link */}
          <div className="min-w-0 space-y-3">
            <div className="flex items-center space-x-3">
              <h2 className="text-sm">{feature.name}</h2>
            </div>
            <p className="flex space-x-2 text-sm text-gray-500">
              <span>{type}</span>
            </p>
          </div>
        </div>
      </Link>
    </li>
  );
};
