import { useState } from 'react';
import { Header, LeftSidebar, MobileMenu } from 'components/Page';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="flex h-screen">
      {/* Narrow sidebar */}
      <LeftSidebar current="Dashboard" />

      {/* Mobile menu */}
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {/* Content area */}
      <div className="flex flex-1 flex-col overflow-auto">
        <Header setMobileMenuOpen={setMobileMenuOpen} />
        {/* Main content */}
        <main className="h-full w-full py-5">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
