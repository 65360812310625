import { useEffect, useState } from 'react';

export type IGetGeoLocation = {
  lat: number;
  lng: number;
  isError: boolean;
  message: string;
};

export type IGeo = {
  geo: IGetGeoLocation | null;
  isLoading: boolean;
};

interface IOptions extends PositionOptions {
  when?: boolean;
}

function getGeoLocation(options: IOptions): Promise<IGetGeoLocation> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          const { coords } = res;
          const { latitude, longitude } = coords;
          resolve({
            isError: false,
            lat: latitude,
            lng: longitude,
            message: '',
          });
        },
        (error) => {
          reject({ isError: true, message: error.message });
        },
        options
      );
    } else {
      reject({
        isError: true,
        message: 'Geolocation is not supported for this Browser/OS.',
      });
    }
  });
}

const defaultGeoLocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: Number.POSITIVE_INFINITY,
  when: true,
};

/**
 * useGeolocation
 * Gets the geolocation data as a hook
 *
 * @param geoLocationOptions Geolocation options
 */
function useGeolocation(
  geoLocationOptions: IOptions = defaultGeoLocationOptions
): IGeo {
  const [isLoading, setIsLoading] = useState(false);
  const [geoObject, setGeoObject] = useState<IGetGeoLocation | null>(null);
  const { when, enableHighAccuracy, timeout, maximumAge } = geoLocationOptions;

  useEffect(() => {
    async function getGeoCode() {
      try {
        setIsLoading(true);
        const value = await getGeoLocation({
          enableHighAccuracy,
          maximumAge,
          timeout,
          when,
        });
        setGeoObject(value);
        setIsLoading(false);
      } catch (error) {
        // @todo this needs to be better.
        setGeoObject(null);
        setIsLoading(false);
      }
    }
    if (when) {
      getGeoCode();
    }
  }, [when, enableHighAccuracy, timeout, maximumAge]);

  return { geo: geoObject, isLoading };
}

export { useGeolocation };
