import { Form } from '../../../components/Forms/shared';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ListBox } from '../ListBox';
import { useAddFeatureContext } from '../AddFeatureContext';
import { Transition } from '@headlessui/react';
import * as z from 'zod';
import { CustomMap } from '../../../components/Map';
import { FormInputError } from '../../../components/Forms/FormInputError';
import {
  CreateTreestandModel,
  FeatureNameModel,
  FeatureTypeModel,
} from '../feature.models';
import { useAppState } from '../../../context';
import { useAddTreestand } from '../api.index';
import { useEffect } from 'react';
import ResultSuccess from '../ResultSuccess';
import ResultFailure from '../ResultFailure';

export const schema = z.object({
  name: z.string().min(1, { message: 'Nameee is required.' }),
  featureType: z.object({
    id: z.string().min(1),
    label: z.string().min(1),
    value: z.string().min(1),
  }),
  feature: z.object({
    id: z.string().min(1),
    label: z.string().min(1),
    value: z.string().min(1),
  }),
  coords: z.object({
    lat: z.number({ required_error: 'Lat and Lng are required.' }),
    lng: z.number({ required_error: 'Lat and Lng are required.' }),
  }),
});

export type ValidationSchema = z.infer<typeof schema>;

interface AddFeatureFormProps {
  features: FeatureNameModel[];
  featureTypes: FeatureTypeModel[];
}

export const AddFeatureForm = ({
  features,
  featureTypes,
}: AddFeatureFormProps) => {
  const { state: AppState } = useAppState();
  const { mutate, isSuccess, apiError } = useAddTreestand();
  const { state, dispatch } = useAddFeatureContext();

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm<ValidationSchema>({
    defaultValues: {
      name: '',
      feature: { id: 'default', label: 'Select a Feature', value: '' },
      featureType: { id: 'default', label: 'Select Feature Type', value: '' },
      coords: {},
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const handleNext = async (step: number) => {
    const result = await trigger(['name', 'feature', 'featureType']);

    if (!result) {
      return;
    }

    dispatch({ type: 'STEP_FORWARD', payload: step });
  };

  const handleBack = () => {
    dispatch({ type: 'STEP_BACK' });
  };

  const handleOnSubmit: SubmitHandler<ValidationSchema> = async (data) => {
    if (!AppState.selectedProperty) {
      return;
    }

    const feature: CreateTreestandModel = {
      ...data,
      featureType: data.featureType.value,
      feature: data.feature.value,
      lat: data.coords.lat,
      lng: data.coords.lng,
      propertyId: AppState.selectedProperty.id,
    };

    mutate(feature);
    await handleNext(3);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch({ type: 'SUCCESS', payload: true });
    }

    if (apiError && apiError?.response?.status !== 201) {
      dispatch({ type: 'SUCCESS', payload: false });
    }
  }, [apiError, dispatch, isSuccess]);

  if (!AppState.selectedProperty) {
    return null;
  }

  const { selectedProperty: { lat = 37.0902, lng = 95.7129 } = {} } = AppState;

  return (
    <>
      <Form name="addFeature" onSubmit={handleSubmit(handleOnSubmit)}>
        <Transition show={state.step === 1} className="mb-8 sm:col-span-4">
          <Form.Row>
            <Form.Label htmlFor="name">Name</Form.Label>
            <Form.Input id="name" inputProps={register('name')} />
            {errors.name?.message && (
              <FormInputError message={errors.name.message} />
            )}
          </Form.Row>
          <Form.Row>
            <Controller
              control={control}
              render={({ field }) => (
                <ListBox
                  label="Feature"
                  value={field.value}
                  onChange={(option) => {
                    clearErrors('feature');
                    field.onChange(option);
                  }}
                  options={features.map((feature) => ({
                    id: feature.id,
                    label: feature.name,
                    value: feature.slug,
                  }))}
                />
              )}
              name="feature"
            />
            {errors.feature?.value?.message && (
              <FormInputError message={errors.feature.value?.message} />
            )}
          </Form.Row>
          <Form.Row>
            <Controller
              control={control}
              render={({ field }) => (
                <ListBox
                  label="Feature Type"
                  value={field.value}
                  onChange={(option) => {
                    clearErrors('featureType');
                    field.onChange(option);
                  }}
                  options={featureTypes.map((featureType) => ({
                    id: featureType.id,
                    label: featureType.name,
                    value: featureType.slug,
                  }))}
                />
              )}
              name="featureType"
            />
            {errors.featureType?.value?.message && (
              <FormInputError message={errors.featureType?.value.message} />
            )}
          </Form.Row>
          <Form.Row>
            <Form.Button type="button" onClick={() => handleNext(2)}>
              Next
            </Form.Button>
          </Form.Row>
        </Transition>
        <Transition
          show={state.step === 2}
          className="mb-8 sm:col-span-6"
          enter="transition-opacity duration-575 delay-[200ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <Form.Row span={6}>
            <>
              <Controller
                control={control}
                render={({ field }) => (
                  <CustomMap
                    onChange={field.onChange}
                    initialLocation={{
                      lat,
                      lng,
                    }}
                    mapId="customMap"
                  />
                )}
                name="coords"
              />
            </>
            {errors.coords?.lat?.message && (
              <FormInputError message={errors.coords.lat.message} />
            )}
          </Form.Row>
          <Form.Row>
            <Form.Button type="button" onClick={handleBack}>
              Back
            </Form.Button>
            <Form.Button className="ml-3" type="submit">
              Submit
            </Form.Button>
          </Form.Row>
        </Transition>
        <Transition
          show={state.step === 3 && state.success}
          className="mb-8 sm:col-span-6"
          enter="transition-opacity duration-575 delay-[200ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <ResultSuccess reset={reset} />
        </Transition>
        <Transition
          show={
            state.step === 3 &&
            apiError !== null &&
            apiError?.response?.status !== 201
          }
          className="mb-8 sm:col-span-6"
          enter="transition-opacity duration-575 delay-[200ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <ResultFailure reset={reset} />
        </Transition>
      </Form>
    </>
  );
};
