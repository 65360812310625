import React from 'react';

interface RowProps {
  children: React.ReactNode;
  span?: number;
}

export const Row = ({ children, span = 4 }: RowProps) => {
  return <div className={`sm:col-span-${span} mb-8`}>{children}</div>;
};
