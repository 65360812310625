import { FeatureListItem } from '../FeatureListItem';
import { PropertyFeatureModel } from '../../../AddFeature/feature.models';

interface FeatureListProps {
  features: PropertyFeatureModel[];
}

export const FeatureList = ({ features }: FeatureListProps) => {
  return (
    <>
      <ul
        role="list"
        className="mt-0 divide-y divide-gray-200 border border-gray-200"
      >
        {features.map((feature) => {
          if (feature.featureId === 'treestand') {
            return (
              <FeatureListItem
                key={feature.id}
                feature={feature}
                type={feature.featureId}
              />
            );
          }
        })}
      </ul>
    </>
  );
};
