import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { PropertyModel } from 'pages/Properties/property.model';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useGetDefaultProperty } from '../../../pages/Properties/propertyApi';
import { useAppState } from '../../../context';

interface PropertyMenuProps {
  properties: PropertyModel[];
}

export const PropertyMenu = ({ properties }: PropertyMenuProps) => {
  const { state, dispatch } = useAppState();
  const queryClient = useQueryClient();
  const [selected, setSelected] = useState<PropertyModel | null>(null);
  useGetDefaultProperty();

  useEffect(() => {
    if (selected?.id === state?.selectedProperty?.id) {
      setSelected(state.selectedProperty);
      return;
    }

    setSelected(state.selectedProperty);
  }, [selected, state.selectedProperty]);

  if (!selected || Object.keys(selected).length < 1) {
    return null;
  }

  const handleOnChange = (item: string) => {
    const newSelected = properties.filter((property) => property.id === item);
    localStorage.setItem('selectedProperty', JSON.stringify(newSelected[0]));
    setSelected(newSelected[0]);
    dispatch({ type: 'SET_SELECTED_PROPERTY', payload: newSelected[0] });
    queryClient.invalidateQueries({
      queryKey: ['journals'],
    });
  };

  return (
    <Listbox
      value={selected.id}
      onChange={(item: string) => handleOnChange(item)}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only block text-sm font-medium leading-6 text-gray-900">
            Select property
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {properties.map((property) => (
                  <Listbox.Option
                    key={property.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={property.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {property.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
