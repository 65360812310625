import { PlusIcon, XCircleIcon } from '@heroicons/react/20/solid';

import { useAddFeatureContext } from '../AddFeatureContext';

interface ResultSuccessProps {
  reset: () => void;
}

export default function ResultFailure({ reset }: ResultSuccessProps) {
  const { dispatch } = useAddFeatureContext();

  return (
    <div className="text-center">
      <div className="flex items-center justify-center">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There was an error with your submission.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
          onClick={() => {
            reset();
            dispatch({ type: 'RESET' });
          }}
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Try again
        </button>
      </div>
    </div>
  );
}
