import React, { Fragment, SetStateAction } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';

// Local imports
import { useAuth } from 'context';
import { useApi, useCurrentUser } from 'hooks';
import { PropertyMenu } from '../PropertyMenu';
import { useGetOwnerProperties } from 'pages/Properties/propertyApi';

interface Props {
  setMobileMenuOpen: React.Dispatch<SetStateAction<boolean>>;
}

const userNavigation = [
  { name: 'Your Profile', href: '/user/profile' },
  { name: 'Properties', href: 'properties' },
  { name: 'Sign out', href: '#' },
];

export const Header = ({ setMobileMenuOpen }: Props) => {
  const { state } = useAuth();
  const queryClient = useQueryClient();
  const axios = useApi();
  const { dispatch } = useAuth();
  const [, , removeCookie] = useCookies(['loggedIn']);
  const navigate = useNavigate();
  const {
    data,
    isLoading: propertiesLoading,
    isError,
  } = useGetOwnerProperties(state.user?.id || '');
  const { data: currentUser } = useCurrentUser();

  const { mutateAsync: signOutUser, isLoading } = useMutation(async () => {
    const result = await axios.post('/auth/logout');
    return result;
  });

  const handleSignOut = async () => {
    removeCookie('loggedIn');
    dispatch({ type: 'SET_ACCESS_TOKEN', payload: '' });

    await signOutUser();
    queryClient.clear();
    navigate('/');
  };

  return (
    <header className="w-full">
      <div className="relative z-10 flex h-16 shrink-0 border-b border-gray-200 bg-white shadow-sm">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-regular md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div
          className={classNames({
            'flex flex-1 px-4 sm:px-6': true,
            'justify-end': propertiesLoading || isError || data.length < 1,
            'items-center justify-between': data && data.length > 0,
          })}
        >
          {propertiesLoading || isError || data.length < 1 ? (
            ''
          ) : (
            <>
              <Disclosure as="nav" className="bg-white">
                {({ open }) => <PropertyMenu properties={[...data]} />}
              </Disclosure>
            </>
          )}
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            {/* Profile dropdown */}
            <Menu as="div" className="relative shrink-0">
              <div>
                <Menu.Button className="flex-center flex h-10 w-10 rounded-full bg-green-regular text-sm text-white focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  {currentUser
                    ? `${currentUser.firstName.charAt(
                        0
                      )}${currentUser.lastName.charAt(0)}`
                    : '...'}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => {
                        if (item.name === 'Sign out') {
                          return (
                            <button
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-full px-4 py-2 text-left text-sm text-gray-700'
                              )}
                              type="button"
                              onClick={handleSignOut}
                            >
                              {isLoading ? 'Signing out' : item.name}
                            </button>
                          );
                        }
                        return (
                          <NavLink
                            to={item.href}
                            className={({ isActive }) => {
                              return classNames(
                                isActive ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              );
                            }}
                          >
                            {item.name}
                          </NavLink>
                        );
                      }}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};
