import React from 'react';
import { format } from 'date-fns';
import { PlusIcon } from '@heroicons/react/24/outline';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as d2d from 'degrees-to-direction';

import { useWeather } from '../../hooks/useWeather';

interface WeatherProps {
  lat: number | string;
  lng: number | string;
}

export const Weather = ({ lat, lng }: WeatherProps) => {
  const { getWeather, setWeather, weather } = useWeather();

  const handleGetWeather = async () => {
    if (!lat || !lng) {
      return;
    }

    const weatherData = await getWeather(lat, lng);

    if (weatherData) {
      setWeather((prevState) => ({
        ...prevState,
        pressure: weatherData?.data?.main?.pressure,
        temp: Math.round(weatherData?.data?.main?.temp),
        sunrise: format(
          new Date(weatherData?.data?.sys?.sunrise * 1000),
          'h:mm'
        ),
        sunset: format(new Date(weatherData?.data?.sys?.sunset * 1000), 'h:mm'),
        wind: {
          gusts: Math.round(weatherData?.data?.wind?.gust),
          speed: Math.round(weatherData?.data?.wind?.speed),
          direction: d2d(weatherData?.data?.wind?.deg),
        },
      }));
    }

    console.log('weatherData', weatherData);
    return null;
  };

  return (
    <>
      <div className="flex justify-between px-4 py-5 sm:p-6">
        <div className="px-4">
          <div className="text-base font-normal text-gray-900">Pressure</div>
          <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-sm font-semibold text-green-regular">
              {weather?.pressure ? weather.pressure : '---'}
              <span className="ml-2 text-xs font-medium text-gray-500">mB</span>
            </div>
          </div>
        </div>
        <div className="px-4">
          <div className="text-base font-normal text-gray-900">Temp</div>
          <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-sm font-semibold text-green-regular">
              {weather?.temp ? `${weather.temp}°` : '---'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between px-4 py-5 sm:p-6">
        <div className="px-4">
          <div className="text-base font-normal text-gray-900">Sunrise</div>
          <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-sm font-semibold text-green-regular">
              {weather?.sunrise ? weather.sunrise : '---'}
              <span className="ml-2 text-xs font-medium text-gray-500">AM</span>
            </div>
          </div>
        </div>
        <div className="px-4">
          <div className="text-base font-normal text-gray-900">Sunset</div>
          <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-sm font-semibold text-green-regular">
              {weather?.sunset ? weather.sunset : '---'}
              <span className="ml-2 text-xs font-medium text-gray-500">PM</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between px-4 py-5 sm:p-6">
        <div className="px-4">
          <div className="text-base font-normal text-gray-900">Wind</div>
          <div className="mt-1 flex items-baseline md:block lg:flex">
            <div className="mr-4">
              <div className="flex items-baseline text-sm font-semibold text-green-regular">
                {weather?.wind?.speed ? weather.wind?.speed : '---'}
                <span className="ml-1 text-xs font-medium text-gray-500">
                  mph
                </span>
                <span className="ml-1 text-sm font-medium text-gray-500">
                  {weather?.wind?.direction ? weather.wind?.direction : '---'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4">
          <div className="text-base font-normal text-gray-900">Gusts</div>
          <div className="mt-1 flex items-baseline md:block lg:flex">
            <div className="mr-4">
              <div className="flex items-baseline text-sm font-semibold text-green-regular">
                {weather?.wind?.gusts ? weather.wind?.gusts : '---'}
                <span className="ml-1 text-xs font-medium text-gray-500">
                  mph
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          className="mb-4 mt-6 inline-flex w-full items-center rounded-md border border-transparent bg-green-regular p-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
          onClick={handleGetWeather}
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          {'Get Weather'}
        </button>
      </div>
    </>
  );
};
