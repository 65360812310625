import { useState } from 'react';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '../../../hooks';
import {
  CreateJournalModel,
  JournalModel,
  UpdateJournalModel,
} from '../journal.models';
import { useQueryClient } from '@tanstack/react-query';
import { JournalTypeModel } from '../journalType.model';

interface Infinite {
  pageParam?: string;
}

export const useInfiniteJournals = (userId: string, propertyId: string) => {
  const axios = useApi();

  return useInfiniteQuery(
    ['journals', propertyId],
    async (options: Infinite) => {
      const url = !options.pageParam
        ? `/journal/${userId}/${propertyId}?cursor=`
        : `/journal/${userId}/${propertyId}?cursor=${options.pageParam}`;

      return await axios.get<JournalModel[]>(url);
    },
    {
      getNextPageParam: (lastPage) => {
        // If there is an empty array.
        if (lastPage.data.length < 1) {
          return undefined;
        }

        return lastPage.data[lastPage.data.length - 1].id ?? undefined;
      },
      enabled: !!userId && !!propertyId,
    }
  );
};

export const useUpdateJournal = () => {
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const axios = useApi();
  const queryClient = useQueryClient();

  return {
    ...useMutation({
      mutationFn: async (data: UpdateJournalModel) => {
        return await axios.patch<UpdateJournalModel>(
          `/journal/${data.id}`,
          data
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['journals']);
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }),
    apiError,
    setApiError,
  };
};

export const useAddJournal = () => {
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const axios = useApi();
  const queryClient = useQueryClient();

  return {
    ...useMutation({
      mutationFn: async (data: CreateJournalModel) => {
        return await axios.post<CreateJournalModel>('/journal', data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['journals']);
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }),
    apiError,
    setApiError,
  };
};

export const useDeleteJournal = () => {
  const [apiError, setApiError] = useState<AxiosError<Error> | null>(null);
  const axios = useApi();
  const queryClient = useQueryClient();

  return {
    ...useMutation({
      mutationFn: async (id: string) => {
        return await axios.delete(`/journal/${id}`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['journals']);
      },
      onError: (error: AxiosError<Error>) => {
        setApiError(error);
      },
    }),
    apiError,
    setApiError,
  };
};

export const useGetJournalTypes = () => {
  const axios = useApi();

  return useQuery({
    queryKey: ['journal-types'],
    queryFn: async () => {
      const results = await axios.get<JournalTypeModel[]>('/journal-types');

      return results.data as JournalTypeModel[];
    },
  });
};
