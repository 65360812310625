import { useContext } from 'react';
import { createCtx } from 'services/createContext';
import { PropertyModel } from '../../pages/Properties/property.model';

const initialState = {
  selectedProperty: null,
  status: {
    registrationCompleted: false,
  },
};

interface AppState {
  selectedProperty: PropertyModel | null;
  status: {
    registrationCompleted: boolean;
  };
}

type Action =
  | { type: 'SET_REGISTRATION_COMPLETED'; payload: boolean }
  | { type: 'SET_SELECTED_PROPERTY'; payload: PropertyModel | null };

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case 'SET_REGISTRATION_COMPLETED':
      return {
        ...state,
        status: {
          ...state.status,
          registrationCompleted: action.payload,
        },
      };
    case 'SET_SELECTED_PROPERTY':
      return {
        ...state,
        selectedProperty: action.payload,
      };
    default:
      throw new Error();
  }
}

const [ctx, AppProvider] = createCtx(reducer, initialState);

const useAppState = () => {
  return useContext(ctx);
};

export { useAppState, AppProvider };
