import React, { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { AddFeatureForm } from './AddFeatureForm';
import { AddFeatureProvider } from './AddFeatureContext';
import { useGetFeatures, useGetFeatureTypes } from './api.index';
import { Loading } from '../../components';

type DatePickerWithIconProps = {
  onClick: (e: React.MouseEvent) => void;
  value?: string;
  placeholder?: string;
};

const DatePickerWithIcon = forwardRef<
  HTMLLabelElement,
  DatePickerWithIconProps
>((props, ref) => {
  return (
    <div className="flex items-center justify-between">
      <label
        className="mr-6 flex items-baseline text-sm font-semibold text-green-regular"
        onClick={props.onClick}
        ref={ref}
      >
        {props.value || props.placeholder}
      </label>
      <button onClick={props.onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 fill-green-regular"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </button>
    </div>
  );
});
DatePickerWithIcon.displayName = 'DatePickerWithIcon';

export const AddFeature = () => {
  const features = useGetFeatures();
  const featureTypes = useGetFeatureTypes();

  if (
    features.isLoading ||
    featureTypes.isLoading ||
    !features.data ||
    !featureTypes.data
  ) {
    return <Loading />;
  }

  return (
    <main className="py-10 px-4 sm:px-8">
      <div className="max-w-3xl border-b pb-4 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-5xl">
        <div className="flex items-center">
          <h1 className="text-2xl font-medium text-gray-900">Add Feature</h1>
        </div>
      </div>
      <div className="mt-8 max-w-3xl lg:max-w-5xl">
        <AddFeatureProvider>
          <AddFeatureForm
            features={features.data}
            featureTypes={featureTypes.data}
          />
        </AddFeatureProvider>
      </div>
    </main>
  );
};
