import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'context/AuthContext';
import { Loading } from 'components/Loading';
import { useGetOwnerProperties } from '../../pages/Properties/propertyApi';

export const RequireProperty = () => {
  const location = useLocation();

  const { state } = useAuth();
  const query = useGetOwnerProperties(state.user?.id || '');

  if (query.isLoading) {
    return <Loading />;
  }

  return query.data && query.data.length > 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  );
};
