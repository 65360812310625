import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { PropertyModel } from 'pages/Properties/property.model';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useAuth } from '../../context';
import { useGetOwnerProperties } from '../../pages/Properties/propertyApi';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

interface PropertyMenuProps {
  value: string;
  onChange: (item: string) => void;
}

export const PropertyFormMenu = ({ onChange, value }: PropertyMenuProps) => {
  const { state: authState } = useAuth();
  const { data, isLoading, isError } = useGetOwnerProperties(
    authState.user?.id || ''
  );
  const [selected, setSelected] = useState<PropertyModel | null>(null);

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }
    const newProp = data?.filter(
      (property) => property.id === authState.user?.defaultProperty
    );

    if (newProp && newProp.length > 0) {
      setSelected(newProp[0]);
    }
  }, [authState.user?.defaultProperty, data, isError, isLoading]);

  if (isLoading || isError) {
    return null;
  }

  if (data?.length < 1) {
    return (
      <>
        <p className="mb-1 block text-sm font-medium text-gray-700">
          Default Property
        </p>
        <Link
          to="/properties/add"
          className="inline-flex items-center rounded-md border border-transparent bg-green-regular px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-light focus:ring-offset-2"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add Property
        </Link>
      </>
    );
  }

  if (!selected || Object.keys(selected).length < 1) {
    return null;
  }

  const handleOnChange = (item: string) => {
    const newProp = data?.filter((property) => property.id === item);

    if (newProp && newProp.length > 0) {
      setSelected(newProp[0]);
      onChange(item);
    }
  };

  return (
    <Listbox value={value} onChange={(item) => handleOnChange(item)}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-700">
            Default property
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.map((property) => (
                  <Listbox.Option
                    key={property.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={property.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {property.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
