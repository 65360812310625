import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import classNames from 'classnames';
import { FormValues } from '../../pages';

type RTL = UseFormRegister<FormValues>;

interface IProps {
  value: string;
  register: RTL;
}

export const TimeOfDay = ({ register, value }: IProps) => (
  <div className="flex flex-col px-4 py-5 sm:p-6">
    <div className="mb-6 text-base font-normal text-gray-500">Time of Day</div>
    <div className="mt-1 flex flex-wrap items-baseline md:block lg:flex">
      <div className="mr-4">
        <input
          {...register('timeOfDay')}
          type="radio"
          value="allDay"
          id="allDay"
          className="hidden"
        />
        <label
          className={classNames({
            'text-white bg-green-regular hover:text-white hover:bg-green-regular focus:ring-green-regular':
              value === 'allDay',
            'text-green-regular bg-white hover:text-green-regular hover:bg-white focus:ring-white':
              value !== 'allDay',
            'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              true,
          })}
          htmlFor="allDay"
        >
          All Day
        </label>
      </div>
      <div className="mr-4">
        <input
          {...register('timeOfDay')}
          type="radio"
          value="morning"
          id="morning"
          className="hidden"
        />
        <label
          className={classNames({
            'text-white bg-green-regular hover:text-white hover:bg-green-regular focus:ring-green-regular':
              value === 'morning',
            'text-green-regular bg-white hover:text-green-regular hover:bg-white focus:ring-white':
              value !== 'morning',
            'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              true,
          })}
          htmlFor="morning"
        >
          Morning
        </label>
      </div>
      <div className="mr-4">
        <input
          {...register('timeOfDay')}
          type="radio"
          value="midDay"
          id="midDay"
          className="hidden"
        />
        <label
          className={classNames({
            'text-white bg-green-regular hover:text-white hover:bg-green-regular focus:ring-green-regular transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium whitespace-nowrap rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"':
              value === 'midDay',
            'text-green-regular bg-white hover:text-green-regular hover:bg-white focus:ring-white transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium whitespace-nowrap rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              value !== 'midDay',
            'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              true,
          })}
          htmlFor="midDay"
        >
          Mid Day
        </label>
      </div>
      <div className="mr-4">
        <input
          {...register('timeOfDay')}
          type="radio"
          value="evening"
          id="evening"
          className="hidden"
        />
        <label
          className={classNames({
            'text-white bg-green-regular hover:text-white hover:bg-green-regular focus:ring-green-regular':
              value === 'evening',
            'text-green-regular bg-white hover:text-green-regular hover:bg-white focus:ring-white':
              value !== 'evening',
            'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              true,
          })}
          htmlFor="evening"
        >
          Evening
        </label>
      </div>
      <div className="mr-4">
        <input
          {...register('timeOfDay')}
          type="radio"
          value="none"
          id="none"
          className="hidden"
        />
        <label
          className={classNames({
            'text-white bg-green-regular hover:text-white hover:bg-green-regular focus:ring-green-regular transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium whitespace-nowrap rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"':
              value === 'none',
            'text-green-regular bg-white hover:text-green-regular hover:bg-white focus:ring-white transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium whitespace-nowrap rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              value !== 'none',
            'transition-colors duration-100 inline-flex items-center justify-center w-20 px-3 py-1.5 mb-4 border text-s font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2':
              true,
          })}
          htmlFor="none"
        >
          None
        </label>
      </div>
    </div>
  </div>
);
