import { PlusIcon } from '@heroicons/react/20/solid';

import { Logo } from '../../../components/Logo';
import { useAddFeatureContext } from '../AddFeatureContext';

interface ResultSuccessProps {
  reset: () => void;
}

export default function ResultSuccess({ reset }: ResultSuccessProps) {
  const { dispatch } = useAddFeatureContext();

  return (
    <div className="text-center">
      <div className="flex items-center justify-center">
        <Logo size="small" />
      </div>
      <h2 className="mt-2 text-xl font-semibold text-gray-900">Feature Added!</h2>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-green-regular py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-green-regular focus:ring-offset-2"
          onClick={() => {
            reset();
            dispatch({ type: 'RESET' });
          }}
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Add Another Feature
        </button>
      </div>
    </div>
  );
}
